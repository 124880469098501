.Controls {
  margin: 0;
  padding: 14px 14px 7px;
  color: #666;
  font-family: "Segoe UI", wf_segoe-ui_normal, helvetica, arial, sans-serif !important;
  font-size: 16px;
  /* height: 100%; */
  width: 100%;
  box-shadow: 0 15px 18px 0px rgb(200 200 200 / 30%);
  border-bottom: 1px solid #d6d6d6;
  user-select: none;
  z-index: 1;
  position: inherit;
}

.date-picker-assignments-mobileview-view > div {
  justify-content: center;
}

.date-picker-assignments-mobileview-view > div :nth-child(2) {
  margin-left: 0px;
}

.date-picker-assignments-mobileview-view > div > input {
  height: 0px;
  padding-left: 0px;
}

.date-picker-assignments-mobileview-view {
  width: 37px;
}

.date-picker-assignments-mobileview-view .ui-input__input {
  width: 140px;
  /* display: none; */
}

.date-picker-assignments-mobileview-view {
  display: flex;
}

.Controls .ui-dropdown__container {
  max-width: 10rem !important;
}
.Controls .ui-dropdown__container input {
  padding-right: 0 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Controls .ui-dropdown__selected-items {
  max-height: 2.5rem !important;
}
.Controls .selTeacher .ui-list {
  width: 18rem !important;
}
.Controls .ui-dropdown__selecteditem__header {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#menubutton-menu-1 {
  min-width: 150px !important;
}
.Controls .jy:hover {
  transition: 0s;
}
.Controls ::-webkit-scrollbar {
  height: 0.8rem !important;
}
.Controls .ui-image {
  width: 3rem;
  height: 3rem;
}
.Controls .ui-dropdown__items-list {
  border-radius: 0px 4px 4px;
}

.leftControls .btn-sm-custom {
  padding: 0 0.6rem !important;
  max-width: 4rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.justify-lbl {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.leftControls {
  display: block;
  /* margin: 0 0 0 12px !important; */
}

.lbl {
  width: 32px;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
}

.andy {
  display: block;
  margin-bottom: 14px;
  height: fit-content;
}

.txt-grey {
  color: #474c51 !important;
}

.an-gap {
  /* margin: 14px 0 0 6px !important; */
  /* margin: 0px 0 0 6px !important */
}
.nextBtn,
.prevBtn {
  display: none !important;
}
.assignment-tour-search-wrapper {
  row-gap: 10px;
}

@media only screen and (max-width: 375px) {
  .assignment-tour-search-next-wrapper {
    flex-direction: column-reverse;
  }
}
@media (min-width: 375px) and (max-width: 505px) {
  .assignment-tour-search-next-wrapper {
    flex-direction: row-reverse;
  }
}
@media only screen and (max-width: 505px) {
  .Attendance.s-ctr .nextBtn {
    display: flex !important;
    margin-top: 14px;
    margin-left: 6px;
  }
  .Attendance.h-ctr .prevBtn {
    display: flex !important;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .Attendance.s-ctr .Controls {
    /* height: calc(100vh - 61px) !important; */
  }

  .Attendance.s-ctr .leftControls .ui-dropdown__container {
    width: calc(100vw - 53px) !important;
  }

  .Attendance.s-ctr
    .leftControls
    .ui-dropdown__container
    .ui-dropdown__trigger-button {
    width: calc(100vw - 72px);
  }
  .Attendance.s-ctr
    .leftControls
    .ui-dropdown__container
    .ui-dropdown__searchinput__input {
    width: 100%;
  }
  .Controls .ui-list {
    width: 100% !important;
  }
  .Attendance.s-ctr .container2 {
    display: none;
  }
  .Attendance.h-ctr .Controls {
    padding: 0px 14px 0px;
  }
  .Controls .ui-text {
    font-size: 14px;
    font-weight: 600;
  }
}

@media (min-width: 728px) and (max-width: 969px) {
}

@media only screen and (max-width: 1060px) {
  .Attendance.h-ctr .Controls {
    /* display: none !important; */
  }
}

@media only screen and (min-width: 1399px) {
  .andy .ctnr {
    padding-top: 11px;
  }
}

@media only screen and (max-width: 1300px) {
  .Controls .ui-dropdown__container {
    max-width: 14rem !important;
  }
}
