.profile_info {
  margin-left: 20px;
}
.profile_info .profile_name {
  font-family: "Nunito Sans";
  font-weight: 600;
  line-height: 1.5;
  font-size: 30px;
  color: #152c5b;
  /* color: #D3313A; */
  margin-bottom: 0;
  text-align: left;
}
.profile_info .profile_name b {
  font-family: "Nunito Sans";
}
.profile_block_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 40px; */
}
.nav_tab_block {
  border-bottom: 1px solid #e2e8f0;
  /* margin: 0 40px; */
}

.avatar_div {
  position: relative;
}

.avatar_div .avatar_profile {
  height: 5.125rem;
  width: 5.125rem;
  border-radius: 50%;
  object-fit: cover;
  transition: all 1s ease;
  display: flex;
}

.avatar_div .avatar_profile img {
  border-radius: 50%;
  transition: all 1s ease;
}

.camera_icon {
  position: absolute;
  display: none;
  filter: invert(1);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(204, 200, 200);
  border-radius: 100%;
  display: none;
  gap: 6px;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  color: #000;
}
.icons {
  display: inline;
  padding: 4px;
}
.icons:hover {
  background-color: black;
  color: #fff;
  border-radius: 100%;
  padding: 7px;
}
.trash_icon:hover {
  background-color: black;
  color: #fff;
  border-radius: 100%;
  padding: 7px;
}
.trash_icon {
  padding: 4px;
}
.icon-block {
  padding-top: 28px;
  padding-left: 18px;
}
.avatar_profile:hover > .camera_icon,
.avatar_profile > .ui-avatar:hover + .camera_icon {
  display: block;
}
.avatar_profile:hover {
  /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
  /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
  /* background: rgba(0, 151, 19, 0.1); */
  /* background-color: #fff;
    cursor: pointer;*/
  position: relative;
  border-radius: 50%;
  /* font-size: 10px;  */
}

/* .avatar_profile:hover,
  .avatar_profile > .ui-avatar.shade:hover {
    -webkit-filter: brightness(65%);
    -webkit-transition: all 10ms ease;
    -moz-transition: all 10ms ease;
    -o-transition: all 10ms ease;
    -ms-transition: all 10ms ease;
    transition: all 10ms ease;
    cursor: pointer;
  } */

.ui-avatar.shade {
  max-height: 100%;
}
.ui-avatar .ui-label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.account_nav_tabs .nav-link.active {
  border-color: rgb(88, 90, 150) !important;
}
.general_info_row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
}
.general_info_text {
  font-size: 15px;
}
.general_info_row .editDiv .edit_btn,
.cancel_btn,
.save_btn {
  padding: 8px 32px !important;
  background-color: rgb(98, 100, 167) !important;
  color: #fff !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0 !important;
  border: 1px solid rgb(225, 223, 221) !important;
  transition: all 1s ease-in-out;
}
.cancel_btn {
  background-color: #fff !important;
  color: rgb(22, 21, 21) !important;
}
.general_info_row .editDiv .edit_btn:hover,
.save_btn:hover {
  background-color: rgb(88, 90, 150) !important;
}
.cancel_btn:hover {
  border-color: rgb(200, 198, 196) !important;
  background-color: rgb(237, 235, 233) !important;
  color: rgb(37, 36, 35) !important;
}
.account-container {
  min-height: 100vh;
  background-color: white;
}
.crop-image-modal {
  max-height: 700px;
  height: 100%;
}

.crop-image-modal .modal-body {
  display: flex;
  justify-content: center;
}

.account-container .profile-action-wrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.select-student-dropdown {
  .ui-dropdown__container {
    max-height: 32px;
  }
}
