.add-member-wrapper {
  .by-name-email-student-wrapper {
    min-height: 114px;
  }
}
.select-class-dropdown {
  ul {
    max-height: 180px;
  }
}
