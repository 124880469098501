.bundle-events-wrapper {
  // overflow-y: auto;
  overflow: initial;

  .scroll-button-wrapper {
    padding: 15px 0 5px 0;
    align-self: stretch;

    &.hidden {
      display: none;
    }
  }

  .scroll-button-wrapper .scroll-button {
    min-width: unset;
    padding: 7px;
    height: 100%;
  }

  .events-container {
    min-height: 225px;
    overflow-x: auto;
  }

  .scroll-events-wrapper {
    overflow-x: auto;
    row-gap: 15px;
    column-gap: 15px;
    width: max-content;
    display: flex;
    padding: 0 15px 10px 0;
  }

  .bundle-event-edit-form-wrapper {
    margin-top: 35px;
    // padding: 30px 0;
    background-color: #fff;

    border-radius: 25px;
    border: 10px solid rgb(239, 239, 239);
  }

  .bundle-event-form-wrapper {
    padding: 30px 0;
  }
}
