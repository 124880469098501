.ui-dialog__overlay {
  z-index: 10000 !important;
}

.student-progress-dialog {
  max-height: 90vh !important;
  @media screen and (max-width: 1200px) {
    width: 90vw !important;
  }
}
