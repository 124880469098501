.wrapper_recaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-recaptcha-div {
  display: flex;
  justify-content: center;
}

.input-recaptcha-input {
  width: 50%;
}

.input-recaptcha-input > div > input {
  width: 100%;
}

.invalid-captcha {
  width: 100%;
  display: flex;
  justify-content: center;
  color: red;
}
