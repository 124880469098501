.blocked-dates-calender {
  .react-calender-day-today {
    background-color: #6264a7;
  }
  .rdrMonth {
    width: 40rem;
    @media screen and (max-width: 900px) {
      width: 80vw;
    }
  }
}
