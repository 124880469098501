.row-sec {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin-top: 16px;
  flex-wrap: wrap;
}

.row-sec-1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.row-sec-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.ant-tooltip-arrow {
  width: 0px !important;
  height: 0px !important;
}

.ant-tooltip-inner {
  padding: 3px 4px !important;
  min-height: 20px !important;
}

.ant-tooltip-placement-bottom {
  padding-top: 0px !important;
}
