.Header {
  margin: 0;
  padding: 0;
  color: #666;
  background-color: #eaeaea;
  font-family: "Segoe UI", wf_segoe-ui_normal, helvetica, arial, sans-serif;
  font-size: 16px;
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
  user-select: none;
  position: sticky;
  top: 0%;
  z-index: 9999;
}

.link-image {
  margin: 0;
  margin-left: 31px;
  margin-top: 10px;
  max-height: 40px;
  border-radius: 2px;
  background-size: cover;
}

.contentIcon {
  text-decoration: none;
  color: #666;
  font-size: 14px;
  font-weight: 600;
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding-left: 0px;
  pointer-events: none;
}

.startUp-btn {
  text-decoration: none;
  color: #666;
  font-size: 14px;
  font-weight: 600;
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding-left: 0px;
  padding-right: 14px;
  pointer-events: none;
  height: 100%;
}

.Header .a:active,
.Header a:hover {
  color: #6465a7;
}

.Header .ui-avatar {
  width: 2.3rem !important;
  height: 2.3rem !important;
}

.Header .ui-avatar span.ui-label {
  width: 2.3rem !important;
  height: 2.3rem !important;
  line-height: 2.3rem !important;
}

.Header .ui-avatar img {
  width: 2.3rem !important;
}

.Header .FlagBtn {
  background-color: transparent;
  border: hidden;
  box-shadow: none;
  padding-right: unset;
}

.selLan span.ui-button__content {
  color: #6264a7;
}

.Header .FlagBtn:active {
  background-color: transparent;
}

.Header .ui-card__header .ui-flex .ui-text {
  font-weight: 600;
}

.Header h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Avatar {
  margin-right: 10px;
  /* background-color: red !important;
  color: red !important; */
}

/* .Avatar .ui-image {
  width: 2rem !important;
} */

.mr-3 {
  margin-right: 10px;
  margin-left: 10px;
}

.mt-2 {
  margin-top: 6px;
}

.button {
  text-decoration: none;
  color: #666;
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
  padding: 0px 0 14px 0;
  margin-top: 6px;
  display: inline-block;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.activeBtn {
  text-decoration: none;
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
  padding: 0px 0 12px 0;
  margin-top: 6px;
  display: inline-block;
  border: none;
  box-shadow: none;
  /* background-color: transparent; */
  /* border-bottom: 3px solid #6264a7; */
  color: #6264a7;
}

.activeBtn.activeTab {
  /* background-color: red; */
  position: relative;
  height: calc(100% - 6px);
}

.DashNav .activeBtn.activeTab {
  height: 100% !important;
}

.activeBtn.activeTab::before {
  content: "";
  position: absolute;
  height: 3px;
  width: 50px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #6264a7;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.DashNav.activeBtn.activeTab:before {
  bottom: -6px !important;
  left: 39% !important;
}

/* Preprod */

.activeTab {
  text-decoration: none;
  height: 32px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
  padding: 0px 0 12px 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  color: #6264a7;
}

@media screen and (max-width: 1110px) {
  .button {
    font-size: 12px;
  }
  .activeBtn {
    font-size: 12px;
  }
  .activeTab {
    font-size: 12px;
  }
}

.rightNav {
  display: block;
  right: 4px;
  margin-top: 15px;
  margin-left: auto;
  position: absolute;
  max-width: 500px;
  display: flex;
  min-width: 150px;
}

.redo > svg {
  height: 18px;
  width: 18px;
}

/* =============== Responsive =============== */

.ctr-right {
  display: none !important;
  position: absolute;
  right: 27px;
}

.ctrl {
  cursor: pointer;
  margin-left: 12px;
}

/* .Ham {
  width: 35px;
  display: block;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.Ham .line.ln1 {
  width: 20px;
  height: 3px; */
/* margin: 5px 4px 0px; */
/* background: #504b4f;
  border-radius: 3px;
}
.Ham .line.ln2 {
  width: 25px;
  height: 3px;
  margin: 4px 4px 0px;
  background: #504b4f;
  border-radius: 3px;
} */

.ham_container .cover {
  height: 70vh;
}

.ham_container .cover .ui-segment {
  border: none;
  box-shadow: none;
  background-color: transparent;
  /* font-weight: 600; */
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0px;
  cursor: pointer;
}

.childrens {
  padding-left: 50px;
}

.childrens .ui-segment {
  padding: 10px 0px 0px !important;
  font-size: 16px !important;
}

.ham_container.active {
  visibility: visible;
  transition-delay: 0.2s;
}

.ham_container {
  visibility: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 61px;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  z-index: 999;
  height: calc(100vh - 61px);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: rgba(0, 0, 0, 0) 1000ms;
  transition: rgba(0, 0, 0, 0) 1000ms;
  font-family: "Karla", sans-serif;
  font-weight: 500;
}

.ham_container::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f3f2f1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition: 300ms ease-out;
  transition: 300ms ease-out;
}

.ham_container.active::before {
  visibility: visible;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.ui-menu__itemindicator {
  margin-top: 5px;
}

.ham-dv.ui-divider::before {
  height: 1px;
  background: #cfcfcf;
  margin: 10px 0px;
  border-radius: 50px;
}

.DashNav {
  margin-left: 15px;
  margin-top: 0px;
}

.DashNav .ui-button__content {
  color: #666 !important;
}

.DashNav .ui-dropdown__items-list {
  width: 200px;
}

.DashNav .ui-button {
  padding: 0 0 0 5px;
}

.DashNav .ui-dropdown__selected-items {
  overflow: hidden;
}

.DashNav .ui-dropdown__container {
  width: 110px;
}

/* @media (min-width:400px) and (max-width:1060px) {} */
@media only screen and (max-width: 790px) {
  .Header .rightNav {
    display: none !important;
  }

  .ctr-right {
    display: flex !important;
  }

  .menu-btn-profile {
    margin: 0px !important;
  }

  .menu-btn-profile .ui-button__content {
    /* font-weight: 600; */
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 790px) {
  .Header nav {
    display: none !important;
  }

  .ctr-right {
    display: flex !important;
  }
}

@media only screen and (min-width: 1315px) {
  .ham_container.active {
    visibility: hidden;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

.Whiteboard.h-ctr .Controls {
  display: none !important;
}

.Whiteboard.h-ctr .class-wrapper {
  height: calc(100vh - 104px) !important;
}

.rightNav .ui-menu__itemcontent {
  margin-right: 0 !important;
}

.DashNav .ui-dropdown__selected-items:before {
  position: absolute;
  background-color: #6264a7 !important;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0px 0px 0.55rem 0.55rem;
  left: 12px;
  top: -17px;
  padding-bottom: 7px;
  content: attr(data-content);
}

.DashNav .ui-dropdown__selected-items.c-none:before {
  content: none !important;
}

.Header .docs-btn {
  margin: 0;
  padding: 0;
  box-shadow: none;
  background-color: transparent !important;
  border: none;
  justify-content: start;
}

.dashboard-btn {
  margin-top: 16px;
  margin-left: 0;
}

.dashboard-menu {
  /* box-shadow: none; */
  margin: 0;
  border: none !important;
}
.dashboard-menu .ui-menu__itemwrapper,
.dashboard-menu .ui-menu__itemwrapper:hover {
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.dashboard-menu-wrapper {
  position: relative;
}
.dashboard-menu-section {
  height: 100%;
}
.dashboard-menu-section.dashboard-selected:before {
  position: absolute;
  background-color: #6264a7 !important;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0px 0px 0.55rem 0.55rem;
  left: 15px;
  top: -17px;
  padding-bottom: 7px;
  content: attr(data-content);
  min-width: 59px;
  justify-content: center;
}

.dashboard-menu .ui-menu__item {
  padding: 7px 10px;
  display: flex;
  align-items: center;
}
.dashboard-menu .ui-menu__item .ui-menu__itemcontent {
  text-decoration: none;
  color: #666;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 1px;
}
.dashboard-menu .ui-menu__item .ui-menu__itemindicator {
  margin: 2px 0;
}

.dashboard-menu.activeTab {
  position: relative;
  height: calc(100% - 6px);
  height: 100% !important;
}

.dashboard-menu.activeTab .selected.ui-menu__item .ui-menu__itemcontent {
  color: #6264a7;
}
.dashboard-menu.activeTab ul .selected.ui-menu__item .ui-menu__itemcontent {
  color: #202020;
}

.dashboard-menu.activeTab::before {
  content: "";
  position: absolute;
  height: 3px;
  width: 50px;
  bottom: 0;
  /* left: 50%; */
  transform: translate(-50%, 0);
  background-color: #6264a7;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  /* bottom: -6px !important; */
  left: 45%;
}

.DashNav .ui-dropdown__item:hover {
  background-color: rgb(243, 242, 241);
}

.input-field .ui-input__input,
.input-field .ui-input__icon {
  color: #000;
}

.copyicon {
  cursor: pointer;
}

/* @media (max-width: 728px) {
  .Header .docs-btn {
    display: none;
  }
} */
