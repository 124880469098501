.Startup {
  height: 100vh;
  background: #ffffff;
}

.Startup .ui-segment {
  box-shadow: none;
}

.Startup .flexBox {
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  background-color: #ffffff;
}

/* @media (max-width: 782px) {
  .Startup .flexBox {
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    background-color: #ffffff;
    flex-direction: column;
  }
} */

@media (max-width: 782px) {
  .vectorImage {
    display: none !important;
  }
}

.vectorImage {
  width: 100%;
  max-width: 600px;
}

.Startup .btn.btn-outline-primary {
  padding-top: 0.2rem !important;
}

.Startup .nav-item .dropdown-menu.show {
  left: -25px !important;
}

.Startup .back-btn {
  border-radius: 25px;
  height: 48px;
  /* margin: 10px; */
}
