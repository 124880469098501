.available-times {
  .date-slider {
    padding: 0 15px;

    .available-date-wrapper {
      padding: 8px 20px;
      border: 1px solid #efefef;
      display: flex !important;
      border-radius: 6px;
      width: fit-content !important;
      cursor: pointer;
      &.selected {
        background-color: #6264a7;
        color: white;
      }
    }
    .slick-prev {
      left: -10px !important;
    }
    .slick-next {
      right: -10px !important;
    }
    .slick-prev,
    .slick-next {
      display: block !important; /* Force display */
      z-index: 1;
    }
    .slick-prev:before,
    .slick-next:before {
      font-size: 20px;
      line-height: 1;
      opacity: 1;
      color: #6264a7;
    }
    .slick-slide {
      div {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.book-slot-dialog {
  @media screen and (max-width: 768px) {
    width: 90vw !important;
  }
}
