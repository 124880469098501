.assignment-submission-page {
  padding: 25px;
  width: 100vw;
}
.attachment-submission-page-section {
  padding: 25px;
}
.teacher-student-attachment {
  padding-left: 15px;
}

@media only screen and (max-width: 992px) {
}

@media (max-width: 809.99px) {
  .attachment-submission-page-section {
    /* margin-top: 15px; */
  }
}
@media only screen and (max-width: 576px) {
  .assignment-submission-page {
    padding: 25px 10px 10px;
  }
  .attachment-submission-page-section {
    padding: 0;
  }
  .teacher-student-attachment {
    padding-left: 0;
  }
  .assignment-detail-card-header {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
