.feedback {
  height: 100vh;
  .feeback-table-wrapper {
  }
  .h-ctr {
    display: none !important;
  }
  .feedback-section {
    // width: calc(100vw - 200px);
  }

  .feedback-section.compact-mode {
    width: calc(100vw - 60px);
  }

  .feedback-sider {
    position: sticky;
    top: 141px;
    z-index: 999;
    height: calc(100vh - 141px);
    .ant-menu-item-selected {
      color: #6163a5;
    }
    .ant-menu-light .ant-menu-item:hover {
      color: #6163a5;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #eae6ff;
    }
    .ant-menu-inline .ant-menu-item::after {
      border-right: 3px solid #6163a5;
    }
  }

  .feedback-wrapper {
    // height: 100%;

    .create-feedback-split-button {
      max-width: 130px;
      border-radius: 4px 0 0 4px;
    }

    .create-feedback-toggle-button {
      border-radius: 0 4px 4px 0;
    }
  }

  @media only screen and (max-width: 575px) {
    .feeback-table-wrapper {
      height: calc(100vh - 142px);
    }

    .Attendance.h-ctr .table-wrapper {
      height: calc(100vh - 61px);
    }

    .Attendance.h-ctr .class-wrapper {
      height: calc(100vh - 209px);
    }

    .Attendance .d-sm-center {
      justify-content: unset;
    }

    .Attendance.h-cls .d-sm-center {
      justify-content: unset;
    }

    .feedback-wrapper {
      position: relative;
    }

    // .feedback-sider {
    //   position: absolute;
    //   height: 100%;
    //   z-index: 1;
    // }

    // .feedback-section {
    //   padding-left: 60px;
    // }
  }

  @media only screen and (max-width: 375px) {
    .feeback-table-wrapper {
      height: calc(100vh - 60px - 103px);
    }
  }
}

.feedback-dialog.ui-dialog {
  @media screen and (max-width: 630px) {
    width: calc(100vw - 140px) !important;
  }
  @media screen and (max-width: 535px) {
    width: calc(100vw - 40px) !important;
  }
}

.feedback-dialog {
  .ui-dialog__content {
    margin-bottom: 0;
    padding-bottom: 1.5rem;
  }
}
