.collapse {
  max-height: 0;
  overflow: hidden;
  transition: all 0.7s linear; /* Adjust the transition duration as needed */
}

.collapse.show {
  max-height: 1000px; /* Adjust the maximum height as needed */
}

.collapse input[type="search"],
.collapse input[type="search"]:focus {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 8px 16px;
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 12px -3px; */
  background-color: #eeeeee;
  overflow: hidden;
  max-height: 600px;
  flex: 1 1 0%;
  max-width: 100vw;
  padding: 12px 20px;
}

.collapse input[type="search"]::-webkit-input-placeholder {
  color: #000;
}
