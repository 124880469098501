.layout-wrapper {
  .chart-layout {
    // min-height: calc(100vh - 120px);
    // height: 100%;
    background-color: white;

    .available-reports-chart-wrapper {
      .chart-image-wrapper {
        align-self: end;

        .no-available-report-text {
          margin-top: 15px;
        }
      }
    }
  }

  .report-wrapper {
    padding: 20px 10px 0;
    flex-grow: 1;

    .title-report-option-wrapper {
      flex-direction: column;

      &.full-screen {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
      }

      .chart-expand-screen {
        margin-left: 5px;
        min-width: 2rem;
        height: auto;
        display: block;
        margin-bottom: -7px;
      }

      .mobile-chart-expand-screen {
        margin-left: 9px;
        min-width: 2rem;
        height: auto;
        display: block;
        margin-bottom: -7px;
      }

      .report-type-button-wrapper {
        padding: 10px;
        row-gap: 10px;
      }
    }
  }

  #datePicker-1 .ui-input__input {
    width: 140px;
  }

  #datePicker-1 {
    display: flex;
  }

  .chart-layout .report-class-wrapper {
    width: 340px;
    margin-right: 3px;
    overflow: auto;
  }

  .chart-layout .card {
    margin-bottom: 10px !important;
    border-radius: 7px !important;
    cursor: pointer;
    border: 2px solid #d3d3d314 !important;
    width: auto;
  }

  .selectedCard {
    width: auto !important;
    margin-bottom: 10px !important;
    border-radius: 7px !important;
    cursor: pointer;
    background-color: #edebe9 !important;
    border: 2px solid gainsboro !important;
  }
  .activeCard {
    width: auto !important;
    margin-bottom: 10px !important;
    border-radius: 7px !important;
    cursor: pointer;
    background-color: #8f97794d !important;
    border: 2px solid #607d3b !important;
  }

  .refreshMsg {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.33333;
  }

  .chart-layout .availableClass {
    flex-direction: column;
  }

  @media only screen and (max-width: 1060px) {
    .d-none {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1060px) {
    .ui-dialog {
      box-sizing: unset !important;
    }
  }

  @media only screen and (max-width: 689px) {
    .chart-layout.h-cls .availableClass {
      display: none !important;
    }
  }

  .d-none {
    display: none;
  }

  .w-125 {
    width: 125px;
  }

  .pl-10 {
    padding-left: 10px;
  }

  .w-125 .ui-card.selectedCard,
  .w-125 .ui-card.card,
  .w-125 .ui-card.activeCard {
    width: 89px;
    padding-bottom: 7px;
  }

  .report-class-wrapper.w-125 {
    width: auto;
    padding-right: 6px;
  }

  .compact-mode-icon {
    width: 118px;
    text-align: center;
    padding-top: 12px;
    cursor: pointer;
  }

  .compact-mode-icon img {
    width: 24px;
  }

  .flex-grow {
    flex-grow: 1;
  }

  .frameTag {
    width: 100%;
    border: none;
  }

  .frameTagimg {
    max-width: 640px;
    width: fit-content;
    width: inherit;
  }

  @media only screen and (max-width: 689px) {
    .frameTag {
      height: calc(100vh - 61px) !important;
    }
  }

  @media (max-width: 620px) {
    .Whiteboard .heroframe {
      display: none !important;
    }
  }

  .Whiteboard .heroframe {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 576px) {
    .chart-layout .availableClass {
      margin-right: 0;
    }

    .chart-layout .pl-sm-1,
    .Whiteboard .pl-sm-1 {
      padding-left: 1rem !important;
    }

    .chart-layout .report-class-wrapper {
      width: 100%;
      padding-right: 15px;
    }

    .chart-layout .report-class-wrapper.w-125 {
      width: auto;
    }
  }

  .class-figure-image {
    justify-content: center;
  }

  @media screen and (max-width: 576px) {
    .class-figure-image {
      justify-content: flex-start;
    }

    .tour-select-lesson {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .chart-layout .available-reports-chart-wrapper {
      flex-direction: column;
      .chart-image-wrapper {
        align-self: center;
      }
    }
  }
}
