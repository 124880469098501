.button-primary {
  background: #6264a7 !important;
  color: white !important;
}
@media screen and (max-width: 500px) {
  .button-primary {
    padding: 0 10px !important;
  }
}

.text-white {
  color: white;
}

.published {
  color: #1aebff;
}

.publish-later {
  color: #dab600;
}

.resubmit {
  color: #7a80eb;
}

.draft {
  color: #cc4a31;
}

.wrapperClassName,
.rdw-editor-toolbar {
  background: #f5f5f5 !important;
}

.btsp a {
  color: black !important;
}

.form-background {
  background: #f5f5f5;
}

.filter-btn-bg {
  background: #6365a7 !important;
}

.text-field-padding {
  padding-top: 20px;
}
.draft-editor .rdw-option-wrapper {
  background-color: #f5f5f5;
}

.primary {
  color: #6365a7;
}

.btn-bg-primary {
  background: #6365a7 !important;
}

.card-outer {
  /* padding: 0;
    margin: 0; */
}
.header-shadow {
  /* background: #1aebff; */
}

.marks-distribution {
  padding: 0px 15px 0px 15px !important;
}

.card-body-height {
  height: 350px;
  overflow-x: scroll;
}

.late-label {
  background: #dc2941;
  border-radius: 25px;
  width: 50px;
  color: white;
}

.date-picker-width {
  width: 50%;
  margin-top: 10px;
}

.rdw-editor-main {
  padding-left: 10px;
  padding-right: 10px;
}

.delete-btn-bg {
  background: #a91b0d !important;
  color: white !important;
}
.delete-btn-bg.disabled {
  background: #edebe9 !important;
  color: #c8c6c4 !important;
  box-shadow: none;
}

.create-assignment-title-button-wrapper {
  row-gap: 15px;
}

.student-submission-card-container {
  padding: 0;
}
.student-submission-card-container .student-submission-title-card {
  background-color: whitesmoke;
  box-shadow: 0 3px 8px 0px rgb(200 200 200 / 30%);
  z-index: 2;
}

.student-submission-card-container .student-submission-detail-card-section {
  font-size: 1.5rem;
  color: #bfbfbf;
  text-align: center;
  font-weight: 600;
}

.student-submission-grading-card-container
  .student-submission-grading-title-card {
  background-color: whitesmoke;
  box-shadow: 0 3px 8px 0px rgb(200 200 200 / 30%);
  z-index: 2;
}
.student-submission-grading-card-container
  .student-submission-grading-card-wrapper
  .student-submission-grading-title-card
  .grades {
  background-color: #6365a7;
  color: white;
  padding: 6px 15px;
  border-radius: 20px;
  font-size: 1rem;
}

.assignment-action-button-wrapper {
  row-gap: 10px;
}

@media screen and (max-width: 576px) {
  .assignment-home-section .all-subject-assignment {
    padding: 5px;
  }
}
