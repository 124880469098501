:root {
  --inputBackGroundColor: #f3f2f1;
}

.create-rubric {
  position: relative;
}

/* nav section */
.create-rubric-nav {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0; */
  padding: 0 15px 15px 20px;
  border-bottom: 0.5px solid #dddddd;
  background-color: white;
  /* z-index: 3; */
}

/* .create-rubric-nav .create-rubric-have-points {
  align-self: flex-end;
} */
.create-rubric-nav .create-rubric-nav-container label {
  margin-bottom: 0;
}
.create-rubric-nav .create-rubric-nav-container {
  row-gap: 10px;
}

.create-rubric-nav .create-rubric-have-points .total-points {
  border-radius: 20px;
  background-color: #6264a7;
  color: white;
  padding: 5px;
  text-align: center;
  max-width: 130px;
  width: 100%;
}

/* body section */

.create-rubric-section .create-rubric-section-container {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.create-rubric-section .main-rubric,
.create-rubric-section .child-rubric {
  padding: 20px;
  display: "flex";
  flex-direction: "column";
  border-radius: 5px;
  margin-top: 20px;
}

.create-rubric-section .main-rubric {
  max-width: 350px;
  min-width: 300px;
}

.create-rubric-section .child-rubric {
  width: 250px;
  margin-right: 10px;
}

.create-rubric-section .create-rubric-input-text .fieldGroup-140,
.create-rubric-section .create-rubric-input-text .fieldGroup-150 {
  background-color: #f3f2f1;
  border: none;
  border-radius: 5px;
}

.create-rubric-section .add-more-criteria-section {
  margin-top: 25px;
}

.create-rubric-footer .footer-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-top: 0.5px solid #dddddd;
  background-color: white;
}

.create-rubric-footer .footer-section .button-group {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
}

@media (min-width: 1060px) {
  .create-rubric-section {
    min-height: calc(100vh - 245px);
  }
}
@media (min-width: 1025px) and (max-width: 1059px) {
  .h-ctr .create-rubric-section {
    min-height: calc(100vh - 160px);
  }
  .s-ctr .create-rubric-section {
    min-height: calc(100vh - 245px);
  }
}

@media (min-width: 1025px) {
  .create-rubric-section {
    /* padding: 63px 10px 75px 10px; */
    padding: 15px;
  }
  .create-rubric-nav-container {
    align-items: center;
  }
  .rubric-bank-dropdown,
  .total-levels-dropdown {
    min-width: 150px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .create-rubric-nav-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .h-ctr .create-rubric-section {
    min-height: calc(100vh - 145px);
  }
  .s-ctr .create-rubric-section {
    min-height: calc(100vh - 245px);
  }

  .create-rubric-section {
    margin: 0;
    /* padding: 205px 10px 75px 10px; */
  }

  .create-rubric-nav .create-rubric-have-points .have-points-container {
    align-items: center;
  }
  .rubric-bank-dropdown,
  .total-levels-dropdown {
    min-width: 260px;
    max-width: 300px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .create-rubric-nav-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .create-rubric-nav .create-rubric-have-points .have-points-container {
    /* display: flex; */
    /* flex-direction: row-reverse; */
    align-items: center;
  }

  .create-rubric-nav
    .create-rubric-have-points
    .have-points-container
    .total-points {
    margin-bottom: 10px;
  }
  .h-ctr .create-rubric-section {
    min-height: calc(100vh - 150px);
  }
  .s-ctr .create-rubric-section {
    min-height: calc(100vh - 245px);
  }

  .create-rubric-section {
    margin: 0;
    /* padding: 205px 10px 75px 10px; */
    padding: 5px 5px 10px;
    /* height: 85vh; */
  }
  .rubric-bank-dropdown,
  .total-levels-dropdown {
    min-width: 260px;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .create-rubric-nav-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .create-rubric-nav .create-rubric-have-points .have-points-container {
    display: flex;
    /* flex-direction: row-reverse; */
    align-items: center;
  }

  .create-rubric-section .child-rubric {
    padding: 0;
  }

  .create-rubric-section {
    margin: 0;
    /* padding: 265px 5px 75px 5px; */
    padding: 5px 5px 10px;
    /* height: 85vh; */
    min-height: calc(100vh - 150px);
  }

  .rubric-bank-dropdown,
  .total-levels-dropdown {
    min-width: 260px;
    max-width: 300px;
  }
}
.mobile-rubric-bank-dropdown {
  min-width: 260px;
  width: 100%;
}
