.tutor-info-card {
  .interest-label,
  .skill-label {
    background-color: #eeefff !important;
    padding: 15px !important;
    margin: 5px;
    div {
      color: #3d3e66;
    }
  }
  .ask-me-about-label {
    background-color: #fef3eb !important;
    padding: 15px !important;
    margin: 5px;
    div {
      color: #f88634;
    }
  }
}
