.setting-tabs {
  .ant-tabs-tab-active,
  .ant-tabs-tab-btn {
    color: #6264a7 !important;
  }
  .ant-tabs-ink-bar {
    background: #6264a7;
    height: 4px !important;
  }
}
