.feedback-response-card-container {
  .view-response-deatil-card {
    .response-statistics-card {
      width: 223px;
      height: 50px;
      margin: 10px;
      border-radius: 7px;
      box-shadow: rgba(0, 0, 0, 0.07) 1px 1px 9px,
        rgba(0, 0, 0, 0.11) 0px 1.6px 3.6px;
      background-color: white;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.07) 1px 1px 9px,
          rgba(0, 0, 0, 0.11) 0px 1.6px 3.6px;
        background-color: white;
      }
    }
    .description-text {
      word-break: break-all;
      max-height: calc(100vh - 480px);
      overflow: auto;
      @media screen and (max-width: 639px) {
        max-height: calc(100vh - 775px);
      }
    }

    .view-response-deatil-card-header {
      background-color: #494b83;
      min-height: 80px;
    }
    video.video-question {
      max-width: 385px;
    }
  }
}
