.tutors-tabs-wrapper {
  max-width: 768px;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
  .tutors {
    margin-top: 40px;
  }
  .add-tutor-model-content {
    .select-students-dropdown,
    .select-teachers-dropdown {
      ul {
        max-height: 8.3rem !important;
      }
    }
  }
}
