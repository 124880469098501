.favorite-event-wrapper,
.myevents-event-wrapper,
.registration-event-wrapper {
  min-height: calc(100vh - 139px) !important;
}
.discover-event-wrapper {
  min-height: calc(100vh - 140px) !important;
}

.h-ctr .myevents-event-wrapper {
  height: 100%;
}

@media screen and (max-width: 980px) {
  .discover-event-wrapper {
    min-height: calc(100vh - 184px) !important;
  }
}
@media screen and (max-width: 767px) {
  .favorite-event-wrapper,
  .myevents-event-wrapper,
  .registration-event-wrapper {
    min-height: calc(100vh - 170px) !important;
  }
  .discover-event-wrapper {
    min-height: calc(100vh - 180px) !important;
  }
}

.discover-event-cards-wrapper,
.favorite-events-cards-wrapper,
.registration-event-cards-wrapper {
  max-height: calc(100vh - 240px);
  overflow: auto;
  margin-top: 10px;
}
.bundle-events-table-data {
  max-height: calc(100vh - 300px);
  overflow: auto;
  min-height: 100px;
}

@media screen and (max-width: 937px) {
  .discover-event-cards-wrapper {
    max-height: calc(100vh - 275px);
  }
}
@media screen and (max-width: 767px) {
  .discover-event-cards-wrapper {
    max-height: calc(100vh - 325px);
  }
  .registration-event-cards-wrapper {
    max-height: calc(100vh - 330px);
  }
  .favorite-events-cards-wrapper {
    max-height: calc(100vh - 335px);
  }
  .bundle-events-table-data {
    max-height: calc(100vh - 350px);
  }
}
@media screen and (max-width: 361px) {
  .registration-event-cards-wrapper {
    max-height: calc(100vh - 382px);
  }
  .favorite-events-cards-wrapper {
    max-height: calc(100vh - 375px);
  }
  .bundle-events-table-data {
    max-height: calc(100vh - 390px);
  }
}
.event-attendees-swiper .slick-slide > div,
.event-speakers-swiper .slick-slide > div {
  margin: 0 10px;
  padding: 5px 0;
}

.tour-event-sidebar > :nth-child(2) {
  top: 18px;
  /* top: 0px; */
}
.Controls.event-filters {
  margin: 0;
  padding: 7px !important;
  color: #666;
  font-family: "Segoe UI", wf_segoe-ui_normal, helvetica, arial, sans-serif !important;
  font-size: 16px;
  /* height: 100%; */
  width: 100%;
  box-shadow: 0 15px 18px 0px rgb(200 200 200 / 30%);
  border-bottom: 1px solid #d6d6d6;
  user-select: none;
  z-index: 1;
  position: sticky;
  top: 61px;
}
.wrapper {
  padding-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
}
.event-details-wrapper .wrapper {
  padding-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 0;
}
.event-form-page .wrapper {
  padding-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 0;
}

.event-cards-wrapper {
  max-height: calc(100vh - 235px);
  overflow: auto;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.row-sec {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin-top: 16px;
}

.row-sec-1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.row-sec-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.events-pana {
  width: 435.33px;
  height: 412px;
}

.blank-button {
  background: #6264a7 !important;
  border-radius: 4px !important;
  /* font-family: "Segoe UI"; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff !important;
}

.event-title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
}

.sub-title {
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #616161;
  margin: 0px;
  margin-top: 5px;
}

.MuiButtonBase-root > .css-1d6wzja-MuiButton-startIcon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.tabs {
  /* width: calc(60% + 40px); */
  width: 100%;
  padding: 40px 40px 0 40px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .tabs {
    padding: 40px 0 0 0;
  }
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  color: #4193ff;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
  color: #000000;
}

.registration-event-filter-wrapper {
  gap: 15px;
}

.event-details-wrapper .resources-wrapper {
  margin-top: 15px;
}

.event-filters {
  /* position: sticky !important;
  top: 61px;
  z-index: 999; */
}

.event-filters .event-date-filter-btn-wrapper {
  gap: 10px;
}

@media screen and (max-width: 767px) {
  .event-filters .event-date-filter-btn-wrapper {
    margin-top: 10px;
  }
}
@media screen and (max-width: 556px) {
  .wrapper {
    margin: 0 10px;
    padding-bottom: 40px !important;
  }
}

.mobile-wrapper {
  margin: 18px 10px 0 10px;
  /* padding-bottom: 40px; */
}

@media screen and (max-width: 820px) {
  .tabs {
    width: 100%;
    /* margin: 10px; */
  }
}

.events-setting-tabs-wrapper {
  width: 60%;
  margin: auto;
}
@media screen and (max-width: 991px) {
  .events-setting-tabs-wrapper {
    width: 100%;
    margin: 0 20px;
  }
}
