.submit-summary-model {
  &.ui-dialog {
    max-width: 40vw !important;
    @media screen and (max-width: 1700px) {
      max-width: 50vw !important;
    }
    @media screen and (max-width: 1000px) {
      max-width: 80vw !important;
    }
  }
  .ui-box {
    margin-bottom: 0 !important;
  }
}
