.meeting-time-slots-wrapper {
  .time-dropdown {
    width: 160px;
    .ui-dropdown__container {
      width: 100%;
    }
    ul {
      width: 160px;
    }
    .ui-dropdown__selected-items {
      overflow: hidden !important;
    }
    @media screen and (max-width: 1537px) {
      width: 120px;
      ul {
        width: 120px;
      }
    }
    @media screen and (max-width: 1030px) {
      width: 100px;
      ul {
        width: 100px;
      }
    }
  }
  .time-slots-wrapper {
    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }
    .time-slots {
      @media screen and (max-width: 768px) {
        justify-content: space-between;
      }
    }
  }
}
