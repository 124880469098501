.Controls {
  &.booking-filters {
    margin: 0;
    padding: 7px !important;
    color: #666;
    font-family: "Segoe UI", wf_segoe-ui_normal, helvetica, arial, sans-serif !important;
    font-size: 16px;
    /* height: 100%; */
    width: 100%;
    box-shadow: 0 15px 18px 0px rgb(200 200 200 / 30%);
    border-bottom: 1px solid #d6d6d6;
    user-select: none;
    z-index: 3;
    // position: sticky !important;
    // top: 61px !important;
  }
}
