.booking-wrapper {
  background-color: #f0f2f5;
  .h-ctr {
    display: none !important;
  }
}

.select-duration-wrapper {
  padding-top: 20px;
  margin-bottom: 10px;
  .select-duration-title {
  }
  .select-duration-dropdown {
    width: fit-content;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
}
