.my-schedules-wrapper {
  height: 100%;
  .my-schedules-header {
    @media screen and (max-width: 568px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      .my-schedules-header-text {
        margin: 0;
      }
    }
  }
  .schedule-cards-wrapper {
    flex-grow: 1;
    max-height: calc(100vh - 170px);
    overflow: auto;
  }
}
