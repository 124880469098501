.submit-feedback {
  .submit-feedback-container {
    .submit-feedback-cards-wrapper {
      min-height: 200px;
      max-height: calc(100vh - 300px);
      overflow: auto;
      @media screen and (max-width: 1649px) {
        max-height: calc(100vh - 330px);
      }
      @media screen and (max-width: 1062px) {
        max-height: calc(100vh - 365px);
      }
      @media screen and (max-width: 835px) {
        max-height: calc(100vh - 405px);
      }
      @media screen and (max-width: 767px) {
        max-height: calc(100vh - 460px);
      }
      @media screen and (max-width: 666px) {
        max-height: calc(100vh - 495px);
      }
    }
    .submit-feedback-card {
      padding: 5px;
      .submit-feedback-card-header {
        background-color: #494b83;
        min-height: 80px;
        position: relative;
        .feedback-status-type-container {
          margin-top: 4.3rem;
          // margin-left: 75%;
          z-index: 2;
          position: absolute;
          left: 0;
          right: 0;
          padding: 0 10px;
          .feedback-feedback-type {
            // padding: 5px;
            border-radius: 50%;
            background-color: #494b83;
            outline: 3px solid white;
            color: white;
          }
          .feedback-end-date {
            padding: 3px 18px;
            border-radius: 13px;
            background-color: black;
            color: white;
          }
          @media only screen and (max-width: 376px) {
            .feedback-end-date {
              font-size: 12px;
            }
          }
        }
      }
      .submit-feedback-card-body {
        .creator-response-section {
          margin-top: 5px;

          .email-text {
            word-break: break-all;
          }
        }
      }
    }
  }
  .submit-feedback-response-container {
    padding: 20px;
    .submit-feedback-section {
      margin-top: 10px;
    }
    .response-validation-text {
      color: #a4262c;
      font-size: 12px;
      font-weight: 400;
      margin: 5px 0 0 5px;
    }
  }
  .mixed-mode-icon {
    font-style: italic;
    margin-left: -3px;
    font-size: large;
    font-weight: lighter;

    &.filter-icon {
      font-weight: normal;
    }
  }

  @media screen and (max-width: 425px) {
    .submit-feedback-response-container {
      padding: 20px 5px;
    }
    .submit-feedback-container {
      .submit-feedback-card {
        .submit-feedback-card-body {
          .creator-response-section {
            flex-direction: column;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.submit-video-response-wrapper {
  video {
    height: 100%;
    width: 100%;
    max-height: 350px;
    max-width: 467px;
    border-radius: 10px;
  }
  .vider-recorder-wrapper {
    max-height: 350px;
    max-width: 467px;
    margin: auto;
  }
}
