.fit-content {
  /* width: fit-content !important; */
  height: fit-content !important;
  /* background: brown !important;
    display: flex;
    align-items: center;    
    justify-content: space-between;
    margin: 300px; */
  padding: 0px !important;
  /* padding-right: px; */
}

.header-padding {
  margin: -20px 20px 0 20px !important;
}

.img-width {
  /* width: 325px; */
  /* object-fit: cover; */
}

.row-outer {
  margin: 0px 23px 23px 23px;
}

.card-outer {
  padding: 0px;
  margin: 0px;
}
.padding {
  margin-top: 30px;
}

.avatar-view {
  margin-top: -2rem;
  margin-left: 75%;
  z-index: 2;
  position: absolute;
  text-align: center;
}
.avatar-view span {
  color: white;
}
.avatar-view.class0 span {
  background-color: #7a80eb;
}
.avatar-view.class1 span {
  background-color: #cc4a31;
}
.avatar-view.class2 span {
  background-color: #1aebff;
}
.avatar-view.class3 span {
  background-color: teal;
}
.avatar-view.class4 span {
  background-color: darkgoldenrod;
}

.student-submission-grading-card-container
  .student-submission-grading-title-card {
  background-color: whitesmoke;
  box-shadow: 0 3px 8px 0px rgb(200 200 200 / 30%);
  z-index: 2;
}

.student-submission-card-container .student-submission-title-card {
  background-color: whitesmoke;
  box-shadow: 0 3px 8px 0px rgb(200 200 200 / 30%);
  z-index: 2;
}

.student-submission-card-container .student-submission-detail-card-section {
  font-size: 1.5rem;
  color: #bfbfbf;
  text-align: center;
  font-weight: 600;
}

.card-outer {
  padding: 0px;
}

.row-middle {
  display: flex !important;
  align-items: center !important;
}
.text-blue {
  color: #6365a7;
}

.completed {
  color: #1aebff;
}
.black {
  background: black !important;
}

.grades-pending {
  color: #dab600;
}

.resubmit {
  color: #7a80eb;
}

.submission-pending {
  color: #cc4a31;
}

.cardbody-height {
  height: 80% !important;
  overflow-y: scroll;
}

.card-height {
  /* padding-bottom: 0px!important;  */
  /* margin: 0px !important; */
  height: fit-content !important;
  background: #f5f5f5 !important;
  box-shadow: 0 3px 8px 0px rgb(200 200 200 / 30%);
  z-index: 2;
}

.Attendance .student-table-wrapper.w-100 {
  width: 100vw;
  /* text-align: center; */
}
.Attendance .student-table-wrapper {
  position: absolute;
  width: calc(100vw - 378px);
  overflow: auto;
  max-height: calc(100vh - 157px);
}
@media only screen and (min-width: 1024px) {
  .attachment-submission-page-section .teacher-student-attachment {
    padding: 0 0 0 25px;
  }
}

@media (min-width: 1060px) and (max-width: 1380px) {
  .Attendance .student-table-wrapper {
    width: calc(100vw - 378px);
    min-height: calc(100vh - 218px);
  }
}

@media only screen and (max-width: 1060px) {
  .student-table-wrapper .ui-table__row {
    display: block !important;
    height: auto !important;
  }

  .student-table-wrapper.student-table-wrapper-w-100 .multi-slc {
    width: calc(100% - 150px);
  }

  .student-table-wrapper .action-notes {
    display: none;
  }

  .Attendance.s-ctr .student-table-wrapper {
    /* height: calc(100vh - 217px); */
    /* height: calc(100vh - 227px); */
  }

  .Attendance.h-ctr .student-table-wrapper {
    min-height: calc(100vh - 60px);
  }

  .Attendance .student-table-wrapper .ui-avatar {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

@media (min-width: 810px) and (max-width: 843px) {
  .Attendance.s-ctr .student-table-wrapper {
    max-height: calc(100vh - 217px) !important;
  }
}

@media (min-width: 746px) and (max-width: 809.99px) {
  .Attendance.s-ctr .student-table-wrapper {
    max-height: calc(100vh - 278px) !important;
  }
}

@media only screen and (max-width: 745.99px) {
  .Attendance.s-ctr .student-table-wrapper {
    max-height: calc(100vh - 288px) !important;
  }
}
@media only screen and (max-width: 689px) {
  .Attendance.h-cls .student-table-wrapper {
    width: 100%;
    padding-inline: 20px;
  }
}

@media only screen and (max-width: 611px) {
  .Attendance.s-ctr .student-table-wrapper {
    max-height: calc(100vh - 300px) !important;
  }

  .Attendance.h-ctr .student-table-wrapper {
    max-height: calc(100vh - 191px) !important;
  }
}

@media only screen and (max-width: 505px) {
  .Attendance.h-ctr .student-table-wrapper {
    max-height: calc(100vh - 61px) !important;
  }
}

@media only screen and (max-width: 358px) {
  .Attendance.h-ctr .student-table-wrapper {
    max-height: calc(100vh - 61px) !important;
  }
  .attachment-submission-page-section {
    margin-top: 0;
    margin-left: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 328px) {
  .Attendance.h-ctr .student-table-wrapper {
    max-height: calc(100vh - 214px) !important;
  }
}

.Attendance .student-table-wrapper-compactMode {
  width: calc(100vw - 133px);
}

.student-table-wrapper.student-table-wrapper-w-100 {
  width: calc(100vw - 140px);
}

@media (max-width: 576px) {
  .Attendance .student-table-wrapper {
    padding-inline: 0 !important;
  }
}

@media only screen and (max-width: 1023px) {
  .attachment-submission-page-section .teacher-student-attachment {
    padding: 25px 0 0 0;
  }
}
