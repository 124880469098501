.public-profile-wrapper {
  .public-profile-container {
    width: 50vw;
    .ask-me-about-container {
      min-height: 200px;
      background-color: #fff;
      padding: 15px 20px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      .ask-me-about-tags-container {
        background-color: #f5f5f5;
        padding: 15px;
      }
      .loader-container {
        height: 108px;
      }
    }
    .skills-container,
    .interests-container {
      min-height: 300px;
      background-color: #fff;
      padding: 15px 20px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      .skills-tags-container,
      .interests-tags-container {
        background-color: #f5f5f5;
        padding: 15px;
      }
      .loader-container {
        height: 208px;
      }
    }
    .ask-me-about-tag,
    .skill-tag,
    .interest-tag {
      border-radius: 20px;
      margin-top: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem;
      background-color: #fff;
      padding-left: 10px;
    }
  }

  .save-details-button {
    width: fit-content;
  }

  @media screen and (max-width: 1024px) {
    width: 70vw;
    flex-direction: column;
    gap: 20px;
    .public-profile-container {
      width: 100%;
    }
    .save-details-button {
      align-self: flex-end;
    }
  }

  @media screen and (max-width: 768px) {
    width: 85vw;
    flex-direction: column;
    gap: 20px;
    .public-profile-container {
      width: 100%;
    }
    .save-details-button {
      align-self: flex-end;
    }
  }
}
