.chart-controls {
  margin: 0;
  padding: 0;
  color: #666;
  background-color: white;
  font-family: "Segoe UI", "wf_segoe-ui_normal", helvetica, arial, sans-serif;
  font-size: 16px;
  /* height: 100%; */
  width: 100%;
  /* box-shadow: 0 15px 18px 0px rgb(200 200 200 / 30%);
  border-bottom: 1px solid #d6d6d6; */
  box-shadow: 0 15px 18px 0px rgba(200, 200, 200, 0.3);
  position: sticky;
  padding-right: 42px;
  top: 61px;
  z-index: 999;
}

.mobile-chart-controls {
  margin: 0;
  padding: 0;
  color: #666;
  background-color: white;
  font-family: "Segoe UI", "wf_segoe-ui_normal", helvetica, arial, sans-serif;
  font-size: 16px;
  height: 100%;
  width: 100%;
  position: relative;
  position: sticky;
  z-index: 1;
  top: 61px;
  box-shadow: 0 15px 18px 0px rgba(200, 200, 200, 0.3);
}

.Flex {
  flex-wrap: wrap;
  /* height: 100px; */
  background-color: white;
  padding: 14px 14px 8px;
}

.leftControls {
  display: block;
  /* margin: 0 0 0 12px !important; */
}

.chart-controls .ui-button {
  border-radius: 4px;
}

/* height: 34px; */

.chart-controls .ui-datepicker {
  margin: 0 !important;
}

.ui-datepicker input {
  /* margin: 0 7px 0 0; */
}

.ui-datepicker .ui-input__input {
  width: 160px;
  /* padding-left: 0; */
  padding-right: 0;
  background-color: white;
}

.datepicker-mobileView .ui-input__input {
  width: 125px;
  padding-left: 7px;
  padding-right: 0;
  background-color: white;
}

.mobile-view > div > div {
  width: 100%;
}

.mobile-view > div > div > div {
  width: 100%;
}

.mobile-dropdown > div > :nth-child(3) {
  position: relative !important;
  inset: 0 !important;
  margin: 0px !important;
  transform: none !important;
}

.classSelector-mobile > div {
  width: 100%;
}

.classSelector-mobile > div > div {
  width: 100%;
}

.classSelector-mobile > div > div > button {
  width: 100%;
}

.mobile-view-datepicker .ui-input__input {
  padding-left: 7px;
  width: 100px;
  padding-right: 0;
  background-color: white;
}

.react-joyride {
  display: none;
}

.search-input > div > input {
  width: 100%;
}

.chart-controls .ui-dropdown__container {
  width: 18rem !important;
}

.chart-controls .class-type .ui-dropdown__container {
  width: 8rem !important;
}

/* 
.chart-controls .ui-dropdown__selected-items{
    max-height: 2.5rem !important;
    background-color: #f3f2f1;
} */

.chart-controls .ui-list {
  width: 18rem !important;
}
.chart-controls .class-type .ui-list {
  width: 8rem !important;
}
.chart-controls .ui-dropdown__selecteditem__header {
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* @media only screen and (max-width: 1060px) {
  .Charts.h-ctr .chart-controls {
    display: none;
  }
} */

.Charts.h-ctr .chart-controls {
  display: none;
}
