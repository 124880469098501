.excel-import-dialog {
    &.ui-dialog {
      background-color: #efefef;
      border-radius: 25px !important;
      width: 91% !important;
      max-width: 1000px;
  
      .action-btn-wrapper {
        margin-top: 15px;
      }
    }
  }