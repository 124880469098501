:root {
  --inputBackGroundColor: #f3f2f1;
}

/* body section */

.student-rubric .rubric-section .main-rubric,
.student-rubric .rubric-section .child-rubric {
  padding: 20px;
  display: "flex";
  flex-direction: "column";
  border-radius: 5px;
  margin-top: 20px;
}
.student-rubric .rubric-section .main-rubric {
  max-width: 350px;
  min-width: 280px;
}
.student-rubric .rubric-section .child-rubric {
  max-width: 280px;
  min-width: 250px;
  margin-right: 10px;
}
.student-rubric .rubric-section .rubric-input-text .fieldGroup-140,
.student-rubric .rubric-section .rubric-input-text .fieldGroup-150 {
  background-color: #f3f2f1;
  border: none;
  border-radius: 5px;
}
.student-rubric .rubric-section .rubric-input-text input,
.student-rubric .rubric-section .rubric-input-text textarea {
  color: black;
}

@media (max-width: 300px) {
  .student-rubric .rubric-section .child-rubric,
  .student-rubric .rubric-section .main-rubric {
    padding: 0;
  }
}
