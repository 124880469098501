@import "~antd/dist/antd.css";

.App {
  margin: 0;
  padding: 0;
  text-align: center;
}

* ::-webkit-scrollbar-thumb {
  min-height: 60px;
}
* ::-webkit-scrollbar {
  width: 5px;
}

:root {
  --filter: 0;
  --modalFilter: 0;
}

.mode,
img {
  filter: invert(var(--filter));
}

.mode.dark {
  --filter: 1;
}

.dark {
  --modalFilter: 0.9;
}

body .dark,
.darkMode {
  filter: invert(var(--filter));
}

.ui-dialog {
  overflow: auto;
  filter: invert(var(--modalFilter));
}

body.dark .ui-popup__content {
  filter: invert(0.9);
}

.version-info {
  position: fixed;
  bottom: 0;
  right: 12px;
  z-index: 9999;
  /* user-select: none; */
  font-size: smaller;
}

.text-muted-100 {
  color: #718096 !important;
}
