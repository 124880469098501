.setting-rbac-container {
  .MuiButtonBase-root > .css-1d6wzja-MuiButton-startIcon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &.tabs {
    width: 60%;
    cursor: pointer;
  }

  .tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
  }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    color: #4193ff;
  }

  .tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    color: #000000;
  }

  .rbac-dashboard-container {
    padding: 0 18px;
    height: calc(100vh - 200px);
    overflow-y: auto;
    width: 100%;
    margin: 20px auto;
  }

  .rbac-list-wrapper {
    .rbac-card {
      .delete-rbac-button {
        color: #de5854;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem;
        border: 1px solid rgb(225, 223, 221);
        width: 33px;
        min-width: 0;
        height: 31px;
        margin-left: 5px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    &.tabs {
      width: 100%;
      margin: 10px;
    }

    .rbac-dashboard-container {
      height: calc(100vh - 146px);
    }
  }
  @media screen and (max-width: 556px) {
    &.tabs {
      width: 100%;
      margin: 0;
    }
    .wrapper {
      margin: 40px 10px;
      padding-bottom: 40px;
    }
  }
}

.edit-rbac-data-wrapper {
  .description-wrapper {
    margin: 15px 0;
    padding: 0 8px;
  }
  .report-options-wrapper {
    .report-option,
    .report-option-header {
      margin: 10px 0;
      padding: 20px;
      align-items: center;

      background-color: #faf9f8;
      &:hover {
        background-color: #edebe9;
      }

      @media screen and (max-width: 320px) {
        padding: 10px;
        text-align: center;
      }

      .role-action-wrapper {
        .role-title {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media screen and (max-width: 375px) {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    .report-option-header {
      font-weight: bold;
      // text-align: center;
    }

    .report-option {
      .title-button-description-text-wrapper {
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        gap: 10px;

        .title-button {
          border-radius: 5px;
          width: max-content;
        }

        @media screen and (max-width: 1024px) {
          flex-direction: column;
          align-items: flex-start;
        }

        @media screen and (max-width: 320px) {
          padding: 10px;
          text-align: center;

          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
        }
      }

      .role-action-wrapper {
        @media screen and (max-width: 375px) {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

.edit-rbac-dialog {
  @media screen and (max-width: 375px) {
    width: 75% !important;
  }
}
