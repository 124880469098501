/* .event .wrapper {
  content-visibility: auto;
} */
.event-wrapper .event-form-wrapper {
  /* max-height: calc(100vh - 190px);
  overflow-y: auto; */
}

@media screen and (max-width: 768px) {
  .event-wrapper .event-form-wrapper {
    /* max-height: calc(100vh - 210px); */
  }
  .event-wrapper .event-form-wrapper .event-form-right-section {
    width: 100%;
    margin-top: 20px;
  }
}
.event-head {
  display: flex;
  justify-content: space-between;
  margin: 14px 32px;
  flex-wrap: wrap;
}

.event-chatgpt-wrapper .chatgpt-icon-button {
  min-width: unset;
  margin-bottom: 3px;
}

.event-chatgpt-wrapper .chatgpt-icon-button .chatgpt-icon {
  width: 25px;
  height: 25px;
}
@media only screen and (max-width: 768px) {
  .event-head {
    /* flex-direction: column-reverse !important; */
    justify-content: start;
    margin: 14px 0;
  }
}

.event-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0 !important;
  line-height: 20px;
  color: #6163a5;
  text-align: left;
}

.input {
  margin-bottom: 24px !important;
}

.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  /* Text / Secondary */

  color: #424242;
}

.dragger {
  height: 216px !important;
  background: #ffffff;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
    0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  margin-bottom: 24px !important;
}
.event-cover-image-dragger {
  height: 216px !important;
  background: #ffffff;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.11),
    0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  /* margin-bottom: 10px; */
  flex-grow: 1;
}

@media screen and (max-width: 599px) {
  .action-buttons {
    margin-top: 15px;
  }
}
.action-buttons .save-event-button div button {
  border-radius: 4px 0 0 4px;
}
.action-buttons .save-event-button button {
  border-radius: 0 4px 4px 0;
}

.event-wrapper .event-form-wrapper .event-date-wrapper {
  margin-bottom: 24px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.event-name-image {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 5px;
}
.event-name-image .sub-title {
  margin-top: 0;
}

.event-name-image img {
  margin-right: 7px;
  width: 15px;
  height: 15px;
}

.demo-editor {
  height: 130px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  scrollbar-color: #d5cfc8 #f3f2f100;
  scrollbar-width: thin;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 9px !important;
  width: "100%" !important;
}

.max-seats-registration-section {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 20px;
}

.limited-seats-section {
  min-height: 32px;
  margin-top: 24px;
}
.limited-seats-section .title {
  margin-bottom: 0;
}

.event-form.google-login-btn {
  display: none !important;
}
