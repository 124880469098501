.rubric-bank-container {
  padding: 20px;

  .class-names {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .MuiButtonBase-root > .css-1d6wzja-MuiButton-startIcon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rubric-list-section {
    width: 100%;
    max-width: 768px;
    margin: auto;
    .lesson-create-button-wrapper {
      gap: 15px;
    }
  }

  .lesson-dropdown-wrapper {
    max-width: 270px;
    width: 100%;
    margin-right: 0;

    .lesson-dropdown {
      width: 100%;
    }

    .filter-icon {
      margin-right: 10px;
      align-self: flex-end;
      padding-bottom: 8px;
    }
  }

  .setting-create-rubric-section {
    margin-top: 15px;

    .rubric-nav-wrapper {
      gap: 15px;
    }

    .rubric-title-input-wrapper {
      max-width: 250px;
      width: 100%;
      margin-right: 0;
    }

    .rubric-level-dropdown-wrapper {
      max-width: 250px;
      width: 100%;

      .rubric-level-dropdown {
        width: 100%;
      }
    }

    .create-rubric-section-container {
      margin-bottom: 25px;
    }
    .rubric-item,
    .rubric-criteria {
      padding: 20px;
      border-radius: 5px;
      margin-top: 20px;
    }

    .rubric-item {
      max-width: 350px;
      min-width: 260px;
    }
    .rubric-criteria {
      max-width: 270px;
      min-width: 230px;
      margin-right: 10px;
    }

    .create-rubric-input-text .fieldGroup-140,
    .create-rubric-input-text .fieldGroup-150 {
      background-color: #f3f2f1;
      border: none;
      border-radius: 5px;
    }

    .add-more-criteria-section {
      margin-top: 25px;
    }
  }

  .create-rubric-footer-section {
    .rubric-action-wrapper {
      gap: 10px;
    }

    .cancel-rubric-bank-button {
      border-radius: 5px;
      &.is-disabled {
        box-shadow: none;
      }
    }
    .save-rubric-bank-button {
      min-width: 98px;
      border-radius: 5px;
    }

    .delete-rubric-bank-button {
      min-width: 98px;
      border-radius: 5px;
      background-color: #a91b0d;
      color: white;
      &.disabled {
        background-color: #edebe9;
        color: #c8c6c4;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .rubric-list-section {
      width: 100%;
    }
  }
  @media screen and (max-width: 556px) {
    .setting-create-rubric-section .rubric-item,
    .setting-create-rubric-section .rubric-criteria {
      max-width: 200px;
    }
  }

  @media screen and (max-width: 426px) {
    .setting-create-rubric-section {
      .rubric-title-input-wrapper {
        width: 100%;
        max-width: unset;
      }
      .rubric-level-dropdown-wrapper {
        margin-top: 10px;
        max-width: unset;
        width: 100%;
        .rubric-level-dropdown {
          width: 100%;
        }
      }
    }
    .lesson-dropdown-wrapper {
      margin-top: 10px;
      max-width: unset;
      width: 100%;
      .lesson-dropdown {
        width: 100%;
      }
    }
    .rubric-action-wrapper {
      justify-content: center;
    }
  }
}
