.preview-center {
  #pagination-card {
    justify-content: center;
  }

  #pagination-dialog {
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, 0);
    bottom: 0;
  }

  #slideshow-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide-dialog {
    overflow: auto !important;
  }

  .dot {
    width: 10px;
    height: 10px;
    margin: 2px;
    border-radius: 50%;
    display: inline-block;
    background-color: #bbb;
  }
  .active {
    max-width: 10px !important;
    background-color: #585a96;
  }
}
