.meet-link-wrapper {
  flex-grow: 1;
  input {
    width: 100%;
  }
}
.meeting-card {
  width: 100% !important;
  border-radius: 15px !important;
  position: relative;
  border: 2px solid rgb(98, 100, 167) !important;
  box-shadow: 0px 6.4px 14.4px 0px #00000021;
  .badge {
    padding: 15px 10px;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    div {
      &.ui-box {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
  }
  // box-shadow: 0px 1.2px 3.6px 0px #0000001c;
  .date-badge {
    padding: 10px;
    position: absolute;
    top: 15px;
    right: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }
}
.cancel-meeting-dialog {
  padding-bottom: 10px !important;
}
