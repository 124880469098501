.cardImg {
  width: 72px;
  height: 56px !important;
  right: 220px;
  border-radius: 7px;
  cursor: pointer;
}

.imgPreview {
  font-size: 13px;
  width: 72px;
  height: 56px;
  border-radius: 7px;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  background-color: rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.88);
  position: relative;
}

.main_card_div {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.camera_icon {
  position: absolute;
  top: 33%;
  left: 40%;
  display: none;
  /* filter: invert(1); */
}

.imgPreview:hover {
  opacity: 0.5;
  -webkit-filter: brightness(10%);
  -webkit-transition: all 10ms ease;
  -moz-transition: all 10ms ease;
  -o-transition: all 10ms ease;
  -ms-transition: all 10ms ease;
  transition: all 10ms ease;
}

.imgPreview:hover > .camera_icon {
  display: block;
}
