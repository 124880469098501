.collection-main {
  width: 100%;
  overflow: auto;
  position: relative;
  padding: 17px;
}

.collection-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15); */
  display: flex;
  flex-direction: column;
}

.collection-card .like-font {
  color: #dc3545;
}

.collection-card .like-icon {
  width: 14px;
  height: 14px;
}

.collection-card .user-image {
  width: min(100%, 24px);
  height: min(100%, 24px);
}

.collection-card .username {
  color: #1890ff;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.collection-card .date {
  white-space: nowrap;
}
.collection-card .user-info {
  gap: 4px;
}

.collection-card .moreInfoBtn {
  background-color: #585a96;
}

.collection-card .addToCollection {
  border: 1px solid #585a96;
  color: #585a96;
}
.img-div {
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.img-div svg {
  width: 100%;
  height: 120px;
  overflow: hidden;
}
.collection-card .collection-img {
  width: 100%;
  height: 100%;
  /* max-height: 150px;
  min-height: 150px; */
}
.footer {
  margin-top: auto;
  padding-top: 1rem !important;
}

.preview-svg,
.preview-svg div {
  width: 100%;
  height: 100%;
}
.preview-svg svg {
  width: 100%;
  height: revert-layer;
}
#preview-dialog .ui-dialog__content {
  display: flex;
  height: inherit;
  width: inherit;
  min-height: 100%;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}
/* .ui-dialog__content > div {
  width: inherit;
  height: inherit;
} */

#preview-dialog {
  text-align: center;
  /* width: fit-content !important; */
  /* height: 600px; */
  height: 60%;
  width: 50% !important;
  overflow: hidden !important;
}

.reasonlabel {
  font-weight: 900;
  font-size: 15px;
}
.reason {
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.reason-label {
  margin-bottom: 0px !important;
  font-size: 14px !important;
}

.collection-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collection-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
