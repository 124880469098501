.ask-buddy-dialog.ui-dialog {
  .ui-dialog__content {
    height: 75vh;
    margin-bottom: 0;
    border-radius: 10px;
  }
  .input-wrapper {
    height: fit-content !important;
    padding: 20px 10px;
    background-color: #f3f2f1;
    border-radius: 10px;
  }
  .chat-area-wrapper {
    max-height: 100%;
    overflow: auto;
    scroll-behavior: smooth;

    .scroll-to-bottom-button {
      position: fixed;
      bottom: 190px;
      right: 56px;
      z-index: 2;
      &:hover {
        background-color: #6264a7;
        color: #fff;
      }
    }
    .chat-area {
      height: 100%;
      padding-bottom: 16px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      blockquote,
      figure {
        margin: 0;
      }
    }
  }

  .pre-built-question-box {
    background-color: #f3f2f1;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    &:hover {
      background-color: #e0e0e0;
    }
    .pre-built-question-text {
      max-width: 100%;
      width: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
