.all-feedback-container {
  height: 100%;
  .all-feedback {
    padding: 20px 20px 0 20px;
    height: 100%;

    .mixed-mode-icon {
      font-style: italic;
      margin-left: -3px;
      font-size: large;
      font-weight: lighter;

      &.filter-icon {
        font-weight: normal;
      }
    }
    .feedback-table-card-wrapper {
      height: 100%;
    }
    .all-feedback-grid-view-wrapper {
      .all-feedback-cards {
        min-height: 190px;
        margin-top: 20px;
        max-height: calc(100vh - 260px);
        overflow-y: auto;
        overflow-x: hidden;
        @media screen and (max-width: 1768px) {
          max-height: calc(100vh - 310px);
        }

        @media screen and (max-width: 1181px) {
          max-height: calc(100vh - 330px);
        }
        @media screen and (max-width: 1060px) {
          max-height: calc(100vh - 365px);
        }
        @media screen and (max-width: 1013px) {
          max-height: calc(100vh - 405px);
        }
        @media screen and (max-width: 870px) {
          max-height: calc(100vh - 440px);
        }
        @media screen and (max-width: 686px) {
          max-height: calc(100vh - 515px);
        }
      }
    }
    .all-feedback-table {
      margin-top: 10px;
      .all-feedback-data {
        overflow-y: auto;
        max-height: calc(100vh - 315px);
        @media screen and (max-width: 1769px) {
          max-height: calc(100vh - 355px);
        }
        @media screen and (max-width: 1181px) {
          max-height: calc(100vh - 410px);
        }
        @media screen and (max-width: 1013px) {
          max-height: calc(100vh - 470px);
        }
        @media screen and (max-width: 870px) {
          max-height: calc(100vh - 505px);
        }
        @media screen and (max-width: 686px) {
          max-height: calc(100vh - 515px);
        }
      }

      .ui-table {
        min-width: unset;
        min-height: 65px;
        .ui-table__header {
          min-height: 50px;
          height: 100%;
        }

        .feedback-title {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
    .all-feedback-table-image {
      height: fit-content;
      padding-top: 50px;
    }

    .feedback-action-wrapper {
      gap: 15px;
    }
  }
  .all-feedback-card {
    // margin: 10px;
    .all-feedback-card-header {
      background-color: #494b83;
      min-height: 80px;
      position: relative;
      .feedback-status-type-container {
        margin-top: 4.3rem;
        // margin-left: 75%;
        z-index: 2;
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 10px;
        .feedback-feedback-type {
          // padding: 5px;
          border-radius: 50%;
          background-color: #494b83;
          outline: 3px solid white;
          color: white;
        }
        .feedback-status {
          padding: 3px 18px;
          border-radius: 13px;
          color: white;
          &.completed {
            background-color: #1db9c3;
          }
          &.progress {
            background-color: #ffb900;
          }
        }
      }
    }
    .all-feedback-card-body {
      padding: 7px 10px;
      margin: 13px 0 10px 0;
      .responses-date-section {
        height: 32px;
      }
    }
  }

  @media only screen and (max-width: 1060px) {
    .ui-table__row {
      display: block !important;
      height: auto !important;
    }

    .ui-table {
      min-width: min-content;
    }

    .ui-table__row:first-child .ui-table .ui-box.ui-table__cell__content {
      width: auto;
    }

    .ui-table .ui-box.ui-table__cell__content {
      width: -webkit-fill-available;
    }

    .note-input input {
      width: -webkit-fill-available;
    }

    .stud-name span {
      font-weight: 600;
    }

    .multi-slc {
      position: fixed;
      background: white;
      z-index: 1;
      width: calc(100% - 386px);
      margin-top: -2px;
      align-items: center;
      padding-left: 15px;
      margin-left: -15px;
      box-shadow: 0 15px 18px 0px rgb(200 200 200 / 30%);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .multi-slc {
      width: calc(100% - 150px);
    }

    .action-notes {
      display: none;
    }

    .d-none {
      display: none !important;
    }

    .ui-avatar {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
    .all-feedback {
      .all-feedback-table-image {
        height: fit-content;
        padding-top: 50px;
      }
      .feedback-action-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
        .feedback-filter-btn-wrapper {
          flex-wrap: wrap;
        }
      }
    }
  }
}
