.edit-profile-wrapper {
  .error-message {
    color: #a4262c;
  }
  .education-information-container {
    .field-study-dropdown,
    .year-dropdown {
      ul {
        margin-bottom: 0;
      }
    }
  }
  .personal-information-container {
    .date-of-birth-datepicker {
      label {
        padding: 0;
        font-weight: normal;
        margin-bottom: 2px;
      }
    }
  }
  .skill-information-container {
    .skill-dropdown {
      ul {
        margin-bottom: 0;
      }
    }
  }
  .contact-information-container {
    .mobile-number-input {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}
