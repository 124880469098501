.schedule-dates-calender {
  width: fit-content;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
  }
  .rdrMonth {
    width: 500px;
    @media screen and (max-width: 1500px) {
      width: 400px;
    }
    @media screen and (max-width: 1200px) {
      width: 500px;
    }
    @media screen and (max-width: 530px) {
      width: 85vw;
    }
  }
}
