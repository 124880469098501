.feedback-tabs-wrapper {
  .group-tabs {
    width: 90%;
    margin: 10px 40px;
    cursor: pointer;
  }

  .group-tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
  }

  .group-tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    color: #4193ff;
  }

  .group-tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    color: #000000;
  }

  @media screen and (max-width: 820px) {
    .group-tabs {
      margin: 10px;
      width: 90%;
    }
  }
}
