.calendar-wrapper {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  .calender {
    @media screen and (min-width: 830px) {
      width: 550px;
      height: 450px;
    }
    width: 450px;
    @media screen and (max-width: 470px) {
      width: 80vw;
    }
    .ant-picker-cell-disabled::before {
      background: none;
    }
  }
}

.month-handler {
}
