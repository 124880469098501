.setting {
  background-color: #ffff;
}

.manage {
  /* padding: 20px; */
}

.f30 {
  font-size: 30px;
  margin: 0 0 10px;
}

.bold {
  font-weight: bold;
}

.Heading-settings {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}

.setting button {
  border-radius: 5px;
}
.setting button.m2 {
  margin: 0.3125rem !important;
}

.setting .container2 {
  display: flex;
  /* padding: 20px 0px; */
  justify-content: center;
  position: relative;
}

/* .setting .mb-1{
    margin-bottom: 5px;
} */

.setting .SmallSelector .ui-dropdown__container {
  width: 11rem !important;
}

.setting .isTabletOrMobileView .ui-dropdown__container {
  width: 8rem !important;
}

.setting .SmallSelector .ui-list {
  width: 11rem !important;
}
.setting .SmallSelector .ui-dropdown__selecteditem__header {
  max-width: 40px;
}
.setting .ColonySelector .ui-dropdown__selected-items,
.setting .SmallSelector .ui-dropdown__selected-items {
  max-height: 4.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}
