#learning-from-web-dialog.ui-dialog {
  width: 80vw !important;
  .ui-dialog__content {
    height: 80vh;
    margin-bottom: 0;
    border-radius: 10px;
  }
  .ui-dialog__header {
   width: 100% !important;
  }
  .summary-content {
    padding: 0 15px;
    /* General Styles */

    /* Typography */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: capitalize;
      margin-bottom: 10px;
      color: #000;
      font-weight: 500;
    }

    p {
      line-height: 1.6;
      margin-bottom: 10px;
    }

    /* Lists */
    ul,
    ol {
      padding-left: 20px;
    }

    li {
      margin-bottom: 5px;
    }

    /* Tables */
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
    }

    table,
    th,
    td {
      border: 1px solid #ddd;
      padding: 10px;
      text-align: left;
    }

    th {
      background: #007bff;
      color: white;
      font-weight: bold;
    }

    td {
      background: #f8f9fa;
    }

    /* Links */
    a {
      color: #007bff;
      text-decoration: none;
      transition: 0.3s;
    }

    a:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }
  &.full-screen-dialog {
    &.ui-dialog {
      width: 100vw !important;
      height: 100vh !important;
      border-radius: 0 !important;
      .ui-dialog__content {
        height: 85vh !important;
      }
    }
  }
}

