.month-slider {
  position: relative;
  width: 370px;
  padding-bottom: 20px;
}

.slider-track {
  height: 2px;
  background-color: #ccc;
  position: absolute;
  top: 50px;
  width: 100%;
}

.slider-thumb {
  width: 30px;
  height: 30px;
  background-color: #6264a7;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  transition: left 0.3s ease;
}

.month-labels {
  position: relative;
  padding-bottom: 40px;
}

.month-label {
  cursor: pointer;
  font-size: 14px;
  color: #000;
  position: absolute;
  font-weight: 500;
}

.month-label.selected {
  color: #6264a7;
  font-weight: bold;
}
