.tutor-profile-wrapper {
  padding: 24px;
  .breadcrumb {
    padding: 15px 0;
  }
  .personal-info-wrapper {
    padding: 22px 32px;
    color: #fff;
    background-color: #392c78;
  }
  .badge {
    padding: 18px 20px;
    margin-bottom: 10px;
  }
  .section {
    margin-bottom: 15px;
  }
  .sub-heading {
    font-size: 16px;
    margin-bottom: 8px !important;
    display: block;
  }
  .left-section {
    width: 45%;
  }
  .right-section {
    width: 45%;
  }
  @media screen and (max-width: 767px) {
    .left-section {
      width: 100% !important;
    }
    .right-section {
      width: 100% !important;
    }
  }
  .empty-data-block {
    height: 50px;
    width: 100%;
  }
}
.ant-back-top-content {
  background-color: #6264a7;
  &:hover {
    background-color: #4c4d89;
  }
}
// .header-bg {
//   background: linear-gradient(to right, #ff0080, #7928ca);
//   color: white;
// }
