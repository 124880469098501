.switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 5px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  border-radius: 24px;
  font-weight: 600;
}

.enable {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  background: #6264a7;
  border-radius: 24px;
  /* font-family: "Segoe UI"; */
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.disable {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  background: #ffffff;
  border-radius: 24px;
  cursor: pointer;
}
