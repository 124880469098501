.event-navbar-wrapper {
  .create-event-button {
    margin-top: 15px;

    div button {
      border-radius: 4px 0 0 4px;
    }
    button {
      border-radius: 0 4px 4px 0;
    }
  }
  .ant-menu-item-selected {
    color: #6163a5;
  }
  .ant-menu-light .ant-menu-item:hover {
    color: #6163a5;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #eae6ff;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #6163a5;
  }
}

.myevents-event-wrapper {
  .event-navbar-wrapper {
    @media screen and (max-width: 767px) {
      height: calc(100% - 186px);
    }
  }
}

.discover-event-wrapper {
  .event-navbar-wrapper {
    @media screen and (max-width: 767px) {
      height: calc(100% - 206px);
    }
  }
}

.favorite-event-wrapper {
  .event-navbar-wrapper {
    @media screen and (max-width: 767px) {
      height: calc(100% - 172px);
    }
  }
}
.registration-event-wrapper {
  .event-navbar-wrapper {
    @media screen and (max-width: 767px) {
      height: calc(100% - 182px);
    }
  }
}
