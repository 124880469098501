/* .justify-center {
    display: flex;
    justify-content: center;

} */
.file-browse-section-outer {
  border: 1px dashed gray;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.btn-bg-primary {
  background: #6365a7;
  color: white;
}

.file-browse-section-inner {
  padding: 35px;
}

.file-browse-section-file {
  color: #6365a7;
}

.attachment-file-outer {
  padding-top: 15px;
}

.attachment-file-inner {
  background: #6365a7 !important;
  color: white !important;
}

.videonote-box {
  margin: 10px;
  border: 1px solid black;
  background: #333;
  height: 170px;
  width: 80%;
  display: flex;
  justify-content: center;
}

.start-button {
  color: white !important;
  background: #6365a7 !important;
  margin-top: 122px;
  border-radius: 25px !important;
  padding-left: 27px !important;
  padding-right: 27px !important;
  /* padding: 10px; */
}

.stop-button {
  color: white !important;
  background: #f34f47 !important;
  margin-top: 52px;
  border-radius: 25px !important;
  padding-left: 27px !important;
  padding-right: 27px !important;
}

.stop-icon-color {
  color: white;
  background: white;
}

.recording-now-color {
  color: white;
  margin-top: 50%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  /* height: 200px; */
  /* border: 3px solid green; */
}

.attachment-btn {
  padding: 20px;
}
