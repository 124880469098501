.CustomFields {
  .m-2 {
    margin: 0.3125rem !important;
  }
  .container {
    max-width: 80vw;
  }

  // .container{
  // .ui-flex{
  // justify-content: space-between;
  // }
  // }
  .i-btn.ui-button {
    border-radius: 6px;
    padding: 1.1rem 1.25rem;
    margin: 4px;
  }
  .cfBtn.ui-button {
    width: 10rem;
    min-width: 10rem;
  }
  @media only screen and (max-width: 650px) {
    .container .ui-flex {
      flex-wrap: wrap;
      align-items: baseline !important;
    }
  }
  .d-cnt {
    display: contents;
  }
}

.ui-dialog {
  width: 60vw !important;
}
