.smallDlg.ui-dialog {
  max-width: 35rem !important;
}

.UploadData .ui-segment {
  margin-top: 20px;
  box-shadow: 0 1px 3px 0 rgb(88 102 126 / 12%),
    4px 4px 30px 0 rgb(88 102 126 / 8%) !important;
  border-radius: 25px;
  padding: 32px;
}

.ui-segment.bg-trans {
  background-color: transparent;
}

.ui-segment.bg-trans2 {
  background-color: #ffffffab;
}

.ui-segment.seg-card {
  margin-top: 0px !important;
  min-height: 26.5rem;
}

.UploadData .ui-button {
  border-radius: 6px;
  padding: 1.1rem 1.25rem;
  margin: 4px;
}

.btnFontSize {
  line-height: 1.4286;
  font-size: 0.875rem;
  white-space: nowrap;
}

.greenTick {
  position: absolute;
  top: 0;
  transform: translate(0, -60%);
  left: 70%;
}

.greenTick > img {
  height: 18px;
  width: 18px;
}

.UploadData h1 {
  color: #333333;
}

.seg-card .ui-segment {
  padding: 0;
  box-shadow: none !important;
}

.bg-gr {
  background-color: #efefef !important;
}

.list {
  max-width: 450px;
  min-width: 265px;
  text-align: left;
}

.processProgress .react-sweet-progress {
  margin-right: 0px !important;
  position: relative;
  /* margin-top: 30px; */
  top: 40px;
}

.processProgress .react-sweet-progress-symbol {
  display: none;
}

.UploadData .checkSet {
  margin-inline: 6px !important;
  grid-template-columns: none;
}

.UploadData .ctrl {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.UploadData .lsn-format-optn {
  text-transform: uppercase;
  color: #6264a6;
  text-decoration: underline;
  cursor: pointer;
  margin: 8px auto;
}

.UploadData .mt-0 {
  margin-top: 0px !important;
}

.UploadData .lh44 .ui-box {
  line-height: 44px !important;
}

.rangePicker {
  width: auto !important;
}

@media only screen and (max-width: 700px) {
  /* .list{
    max-width: auto;
    min-width: auto;
  } */
  .bg-gr {
    background-color: white !important;
  }

  .UploadData .ui-segment {
    box-shadow: none !important;
    margin: 0px;
    margin-bottom: 25px;
    padding: 0px;
    /* box-shadow: 0 1px 3px 0 rgb(88 102 126 / 12%), 4px 4px 30px 0 rgb(88 102 126 / 8%) !important; */
    border-radius: 20px;
    /* padding: 32px; */
    background: none !important;
  }

  .processProgress {
    margin-right: -20px !important;
  }
}

@media only screen and (max-width: 640px) {
  .prodgressBtnDiv .progress_btn {
    margin-top: 8px;
    padding: 5px !important;
    border-radius: 15px !important;
  }
}

@media only screen and (max-width: 400px) {
  .prodgressBtnDiv .progress_btn {
    margin-left: 1px !important;
    margin-right: 1px !important;
    padding: 5px !important;
    border-radius: 15px !important;
  }

  .processProgress .react-sweet-progress {
    top: 0;
  }
}

@media only screen and (max-width: 450px) {
  .rangePicker .rdrMonth {
    width: 100% !important;
  }

  .rangePicker {
    padding: 14px !important;
  }
}

.NavBtn .ui-button__content {
  padding-left: 3px;
}

.NavBtn {
  border-radius: 25px !important;
  height: 50px !important;
  margin: 10px !important;
}

.UploadData .active {
  background-color: #585a96;
  color: #fff;
}

.prodgressBtnDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.prodgressBtnDiv .progress_btn {
  padding: 8px;
  border: 1px solid rgb(225, 223, 221);
  z-index: 2;
  box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  margin-left: 7px;
  margin-right: 7px;
  border-radius: 25px;
}

.prodgressBtnDiv .active {
  padding: 8px;
  border: 1px solid rgb(225, 223, 221);
  z-index: 2;
  box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
  transition: all 100ms ease 0s;
  background-color: #6264a7;
  margin-left: 15px;
  border-radius: 25px !important;
}

/* ======== */
/* Wizard Style*/
/* * {
  margin: 0;
  padding: 0
} */

html {
  height: 100%;
}

#grad1 {
  background-color: #9c27b0;
  background-image: linear-gradient(120deg, #ff4081, #81d4fa);
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset .form-card {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px 40px 30px 40px;
  box-sizing: border-box;
  width: 94%;
  margin: 0 3% 20px 3%;
  position: relative;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform fieldset .form-card {
  text-align: left;
  color: #9e9e9e;
}

#msform input,
#msform textarea {
  padding: 0px 8px 4px 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 16px;
  letter-spacing: 1px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  font-weight: bold;
  border-bottom: 2px solid skyblue;
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  background: skyblue;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px skyblue;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
}

select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px;
}

select.list-dt:focus {
  border-bottom: 2px solid skyblue;
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #000000;
}

#progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
}

/* #progressbar #account:before {
  font-family: FontAwesome;
  content: "\f023"
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007"
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f09d"
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c"
} */

#progressbar li {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

/* #progressbar li {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1
} */

#progressbar li.active {
  background: skyblue;
}

.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  width: 204;
  height: 104;
  border-radius: 0;
  background: lightblue;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  cursor: pointer;
  margin: 8px 2px;
}

.radio:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrCalendarWrapper {
  font-size: 14px !important;
}

.rangePicker .ui-dialog__content {
  display: flex;
}

.emptyExcelFileDataModal .ui-dialog__content {
  margin-bottom: 0;
}
.emptyExcelFileDataModal .ui-dialog__footer {
  text-align: left;
}

.internet-connection {
  width: 40% !important;
}

.internet-connection > h2 {
  width: 100% !important;
}

.internet-connection > div > :nth-child(1) {
  justify-content: center;
  display: flex;
}
