.student-progress-page {
  .StudentData {
    .s-studentData {
      max-height: calc(100vh - 270px) !important;
      @media screen and (max-width: 576px) {
        max-height: calc(100vh - 210px) !important;
      }
    }
    .pagination {
      .active {
        border: 0 !important;
      }
    }
  }
}
