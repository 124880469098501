.StudentData .img,
.m-img {
  border-radius: 6px;
  width: 200px !important;
  height: 200px !important;
}

.student-image-info-wrapper {
  max-width: 210px;
  width: 100%;
  margin-top: 15px;
}

.student-image-info-wrapper .break-word {
  word-break: break-all;
}

.student-image-info-wrapper .field-value {
  text-overflow: ellipsis;
  overflow: hidden;
}

.student-info-wrapper {
  /* margin-top: 15px; */
}

.info-chart-wrapper {
  flex-grow: 1;
  row-gap: 10px;
  overflow: hidden;
  width: 100%;
}

.statictics-container {
  overflow-x: auto;
  min-height: 225px;
}
.statictics-wrapper {
  row-gap: 15px;
  column-gap: 15px;
  margin-top: 10px;
  width: max-content;
  display: flex;
}

.student-data-wrapper {
  row-gap: 15px;
}

.student-info-wrapper .subjects-wrapper {
  row-gap: 3px;
}

.selectable-option {
  cursor: pointer;
  transition: 0.2s all ease-in;
}

.selectable-option.active-chart,
.selectable-option:hover {
  color: #6264a7;
  font-weight: bold;
}

.StudentData .m1 {
  margin: 10px 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.ui-dialog__content .m1 {
  margin: 10px 20px 0;
  word-break: break-all;
}
.ui-dialog {
  border-radius: 8px !important;
}
.StudentData .seg {
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  transition: #fff 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 8px;
  margin: 8px !important;
  align-self: stretch;
}

.ui-dialog__content .pb2 {
  padding-bottom: 3px !important;
}
.ui-dialog__content .pr2 {
  padding-right: 6px !important;
}

.ui-dialog__content {
  font-family: "Karla", sans-serif !important;
  color: #212529;
}

/* .c-brand{
  color:#6264a7;
} */

.d-img {
  max-width: 200px !important;
  max-height: 200px !important;
  height: auto;
  width: -webkit-fill-available;
  border-radius: 8px;
}

@media only screen and (max-width: 1200px) {
  .setting .ui-dialog {
    width: 60vw !important;
  }
}
@media only screen and (max-width: 1000px) {
  .setting .ui-dialog {
    width: 80vw !important;
  }
}
/* @media only screen and (max-width: 450px) {
  .ui-dialog__content .m1 {
    margin: 10px 0 0;
  }
} */

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0.625rem !important;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  /* z-index: 3; */
  color: #fff;
  cursor: default;
  background-color: #6264a7;
  border-color: #6264a7;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}
.pager li {
  display: inline;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}
.pager li > a:hover,
.pager li > a:focus {
  text-decoration: none;
  background-color: #eee;
}
.pager .next > a,
.pager .next > span {
  float: right;
}
.pager .previous > a,
.pager .previous > span {
  float: left;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer !important;
}
.label:empty {
  display: none;
}
.btn .label {
  position: relative;
  top: -1px;
}
.label-default {
  background-color: #777;
}
.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}
.label-primary {
  background-color: #6264a7;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #6264a7;
}
.label-success {
  background-color: #5cb85c;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}

.pagination a {
  cursor: pointer;
}

.tlow {
  text-transform: lowercase;
}

.info-chart-wrapper .scroll-button-wrapper {
  padding: 15px 0;
  align-self: stretch;
}

.info-chart-wrapper .scroll-button-wrapper .scroll-button {
  min-width: unset;
  padding: 7px;
  height: 100%;
}

/* .StudentData .filter-wrapper {
  display: flex;
  width: 98%;
  justify-content: space-between;
  margin: auto;
} */

@media (min-width: 1500px) or (max-width: 600px) {
  .info-chart-wrapper .scroll-button-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  /* .student-image-info-wrapper {
    max-width: unset;
  } */
}
@media screen and (max-width: 768px) {
  .student-teacher-progress-wrapper {
    flex-direction: column;
  }
}

.student-event-calender-wrapper {
  .ant-card-body {
    padding: 0 15px !important;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date-content {
    height: 46px;
  }
  .ant-picker-calendar-date-today {
    background: #eae6ff !important;
    border-color: #6264a7 !important;
  }
  .ant-picker-cell-selected,
  .ant-picker-calendar-date-today,
  .ant-picker-calendar-date-value {
    color: #6264a7 !important;
  }
}

.student-progress-card {
  .ant-card-body {
    padding: 24px 24px 0 !important;
  }
}
 