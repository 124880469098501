.booking-sidebar-wrapper {
  .ant-layout-sider-zero-width-trigger {
    top: 0 !important;
  }
  height: calc(100vh - 61px);
  .create-event-button {
    margin-top: 15px;

    div button {
      border-radius: 4px 0 0 4px;
    }
    button {
      border-radius: 0 4px 4px 0;
    }
  }
  .ant-menu-item-selected {
    color: #6163a5;
  }
  .ant-menu-light .ant-menu-item:hover {
    color: #6163a5;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #eae6ff;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #6163a5;
  }
  @media screen and (max-width: 767px) {
    height: calc(100% - 172px);
  }
  @media screen and (max-width: 767px) {
    height: calc(100% - 206px);
  }
}
