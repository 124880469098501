@media screen and (max-width: 576px) {
  .share-event-dialog {
    &.ui-dialog {
      width: calc(100vw - 40px) !important;
    }
  }
}

.share-event-wrapper {
  max-height: 750px;
  height: 100%;
  .share-event-tabs {
    .share-event-tab-list {
      border-bottom: 1px solid #ccc;
      padding-left: 0;

      .share-event-tab-list-item {
        display: inline-block;
        list-style: none;
        margin-bottom: -1px;
        padding: 0.5rem 0.75rem;
        color: #4193ff;
        cursor: pointer;

        &.active {
          background-color: white;
          border: solid #ccc;
          border-width: 1px 1px 0 1px;
          color: #000000;
        }
      }
    }
  }
  .share-event-link {
    .share-event-link-container {
      display: flex;
      flex-wrap: wrap;

      row-gap: 15px;
      .email-invite-wrapper {
        width: 60%;

        .emails-wrapper {
          min-height: 45px;
        }

        .action-button-wrapper {
          margin-top: 18px;
          .share-event-action-buttons {
            row-gap: 15px;
            flex-wrap: wrap-reverse;
          }
        }

        .invalid-text {
          color: #c4314b;
        }
      }
      .qr-code-action-wrapper {
        width: 40%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 18px;

        .qr-image-wrapper {
          max-width: 150px;
          max-height: 150px;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .share-event-link-container {
        .email-invite-wrapper {
          width: 100%;
        }
        .qr-code-action-wrapper {
          width: 100%;
        }
      }
    }
    @media (max-width: 425px), (min-width: 768px) and (max-width: 1024px) {
      .share-event-link-container {
        .qr-code-action-wrapper {
          .download-extra-text {
            display: none;
          }
        }
      }
    }
  }
  .share-event-iframe {
    font-size: 20px;
    font-weight: 500;
    height: 100%;

    .iframe-wrapper {
      display: flex;
      justify-content: center;
      //   height: 100%;
      //   // iframe {
      //   //   min-height: 350px;
      //   //   display: block;
      //   //   height: 100%;
      //   //   width: 100%;
      //   //   border: none;
      //   // }
    }

    .iframe-link-wrapper {
      margin-top: 15px;

      .iframe-link {
        input {
          color: black;
          font-size: 16px;
        }
      }
    }
    .iframe-action-buuton-wrapper {
      margin-top: 10px;
    }
  }
}
