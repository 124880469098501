.groups-wrapper {
  .groups {
    padding: 20px 20px 0;
    .all-groups {
      margin-top: 10px;
      height: calc(100vh - 230px);
      overflow: auto;
    }
    .group-card-body {
      padding: 0 10px;
      min-height: 32px;
    }
  }
  .group {
    padding: 30px 10px 0 10px;
    .group-image-mobile {
      display: none;
    }
    .m1 {
      margin: 10px 10px 0;
    }
    .img {
      border-radius: 6px;
    }

    .seg {
      cursor: pointer;
      box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
        0 1px 3px 1px rgb(60 64 67 / 15%);
      transition: #fff 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 8px;
      margin: 8px !important;
    }

    .student-card-wrapper {
      cursor: pointer;
      box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
        0 1px 3px 1px rgb(60 64 67 / 15%);
      transition: #fff 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 8px;
    }
    @media screen and (max-width: 1023px) {
      .group-image {
        display: none;
      }
      .group-image-mobile {
        display: block;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .group {
      padding: 30px 5px;
    }
  }
  @media screen and (max-width: 576px) {
    .groups {
      padding: 20px 5px 0;
    }
    .group {
      padding: 30px 0px;
    }
  }
}

.student-card-body {
  .student-name-wrapper {
    font-size: 14px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 118px;
  }
}
