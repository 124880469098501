.parent-container {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  margin-top: 40px;
  .parent-info-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0 ;
  }
}

.parent-input {
  input {
    width: 100%;
  }
}

.upload-single-parent-model-content {
  .select-students-dropdown {
    ul {
      max-height: 8.3rem !important;
    }
  }
  .select-provider-dropdown {
    .ui-button__content {
      font-weight: 400 !important;
    }
  }
}
