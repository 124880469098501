.parents-lists-wrapper {
  max-height: calc(100vh - 350px);
  overflow: auto;
  @media screen and (max-width: 1553px) {
    max-height: calc(100vh - 430px);
  }
  @media screen and (max-width: 991px) {
    max-height: calc(100vh - 340px);
  }
  @media screen and (max-width: 639px) {
    max-height: calc(100vh - 440px);
  }
}
