.view-response-container {
  .view-response {
    margin-bottom: 20px;

    .question-wrapper {
      margin: 15px 0;
    }
    .back-button-wrapper {
      margin: 15px 0 0 15px;
    }
    .view-response-student-section {
      padding: 15px 25px 0;
    }
    .view-response-deatil-card {
      .view-response-deatil-card {
        background-color: #494b83;
        min-height: 80px;
      }
    }
    .audio-feedback-card {
      audio::-webkit-media-controls-panel {
        background-color: #5a5b8c;
      }
      audio::-webkit-media-controls-play-button {
        background-color: white;
        border-radius: 50%;
      }
      audio::-webkit-media-controls-time-remaining-display {
        color: #fff;
      }
      audio::-webkit-media-controls-current-time-display {
        color: #fff;
      }
    }
    .m1 {
      margin: 10px 10px 0;
    }
    .img {
      border-radius: 6px;
    }
    .seg {
      cursor: pointer;
      box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
        0 1px 3px 1px rgb(60 64 67 / 15%);
      transition: #fff 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 8px;
      margin: 8px !important;
    }

    .poll-response-card-name-section {
      height: 30px;
    }

    .question-navigation-btn-wrapper {
      gap: 10px;
    }
  }

  .student-response-data {
    max-height: calc(100vh - 375px);
    overflow: auto;
    @media only screen and (max-width: 1023px) {
      max-height: calc(100vh - 445px);
    }
  }

  @media only screen and (max-width: 992px) {
    .view-response {
      .back-button-wrapper {
        margin: 15px 0;
      }
      .view-response-student-section {
        padding: 0px;
      }
    }
  }
}
