.Auth {
  height: 100vh;
  background: #ffffff;
}

.Auth image {
  margin: auto;
}

.Auth .ui-segment {
  box-shadow: none;
}

.Auth .ui-button {
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.32),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.28);
  border-radius: 4px;
  height: 41px;
  padding-left: 25px;
  /* margin-bottom: 12px; */
}

.Auth p {
  font-size: 20px;
}

/* .btsp svg {
  height: 21px;
} */

.Auth h1 {
  font-size: 2em !important;
  font-weight: bold !important;
  margin: 0.67em 0 !important;
}

.Auth .btn {
  padding-left: 20px !important;
  background-color: #d3313a !important;
  color: #fffdfd !important;
  border-color: #d3313a !important;
  padding-top: 8px;
}

.Auth button {
  margin-right: 6px !important;
  margin-left: 6px !important;
}

.Auth .btn:hover {
  background-color: #b62d34;
  color: #fffdfd;
  border-color: #b62d34;
}

.Auth .ui-button__content {
  font-size: 14px;
}

.Auth .btn .ui-button__content {
  font-size: 14px;
  padding-left: 0px;
}

.Auth .flexBox {
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.google_btn {
  border: 1px solid #9b99af !important;
  min-width: 10.83333333vw !important;
  border-radius: 4px !important;
  padding: 0.1rem 0.75rem !important;
  box-shadow: 0px 0.3px 0.9px rgb(0 0 0 / 32%), 0px 1.6px 3.6px rgb(0 0 0 / 28%) !important;
  margin-bottom: 12px !important;
  display: inline;
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #121212 !important;
}

.google_btn > div > div > div > div {
  border: none;
  box-shadow: none;
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #121212 !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-family: "Karla", sans-serif !important;
}

.google_btn iframe .nsm7Bb-HzV7m-LgbsSe {
  border: 5px solid red;
}
