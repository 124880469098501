.student-pending-assignment-list {
  width: 100vw;

  .assignment-list {
    padding: 25px;
  }

  @media only screen and (max-width: 350px) {
    .assignment-list {
      padding: 0;
    }
  }
}
