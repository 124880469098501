.gpt-dialog {
  border: 1px solid #0000006e;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  z-index: 1;
  min-width: 450px;
  min-height: 250px;
  padding: 20px;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .close-button {
      min-width: unset;
    }
  }
  .credit-limit-wrapper {
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 0.275rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin-bottom: 1rem;
    .credit-icon-wrapper {
      display: flex;
      width: 25px;
    }
    .credit-score {
      padding: 0 10px;
    }
  }

  .description {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .myTextarea {
    border: 1px solid #ced4da; /* Set the border color here */
    margin-bottom: 10px;
    border-radius: 4px;
    width: 100%;
    padding: 10px;
  }

  .generate-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
  }

  .tos-text {
    color: #7678c8;
    margin-left: 5px;
  }

  .gpt-icon {
    right: -8px;
    top: 250px;
    .tool-button {
      padding: 0;
      border-radius: 20px 0 0 20px !important;
    }
    .ToolIcon_type_button {
      border-radius: 20px 0 0 20px !important;
    }

    .popover-mobile {
      position: absolute;
      background-color: #fff;
      border-radius: 8px;
      padding: 16px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      z-index: 1;
      left: -5px;
      top: 50%;
      transform: translate(-100%, -50%);
    }
  }

  .credit-limit-wrapper {
    border-radius: 20px;
    .credit-icon-wrapper {
      width: 25px;
    }
  }

  textarea.dark::placeholder {
    color: #fff;
  }
  .dark {
    background-color: grey;
    color: white;
  }

  .dropdown-wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 1rem;
  }

  .condition-generate-btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .condition-text {
      margin-bottom: 0;
    }
  }
}
.gpt-form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
