.bundle-wrapper {
  height: 100%;

  .bundle-table-wrapper {
    // background-color: transparent;
    border-radius: 20px;
  }

  .ui-table {
    min-width: unset;
    .ui-table__header {
      min-height: 50px;
      height: 100%;
    }
    .ui-table__row {
      background-color: transparent;
    }

    .bundle-title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }

  //   .all-feedback-table-image {
  //     height: calc(100% - 120px);
  //     overflow: auto;
  //   }

  @media only screen and (max-width: 1060px) {
    .ui-table__row {
      display: block !important;
      height: auto !important;
    }

    .ui-table {
      min-width: min-content;
    }

    .ui-table__row:first-child .ui-table .ui-box.ui-table__cell__content {
      width: auto;
    }

    .ui-table .ui-box.ui-table__cell__content {
      width: -webkit-fill-available;
    }

    .note-input input {
      width: -webkit-fill-available;
    }

    .stud-name span {
      font-weight: 600;
    }

    .multi-slc {
      position: fixed;
      background: white;
      z-index: 1;
      width: calc(100% - 386px);
      margin-top: -2px;
      align-items: center;
      padding-left: 15px;
      margin-left: -15px;
      box-shadow: 0 15px 18px 0px rgb(200 200 200 / 30%);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .multi-slc {
      width: calc(100% - 150px);
    }

    .action-notes {
      display: none;
    }

    .d-none {
      display: none !important;
    }

    .ui-avatar {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  }
}
