.teacherData .img {
  border-radius: 6px;
}
.teacherData .m1 {
  margin: 10px 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;  
  width: 100%;
}
.teacherData .seg {
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  transition: #fff 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 8px;
  margin: 8px !important;
  align-self: stretch;
}

.teacherData .filter-wrapper {
  display: flex;
  width: 98%;
  justify-content: space-between;
  margin: auto;
}
