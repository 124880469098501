.SelectDB image {
  margin: auto;
}

.SelectDB .ui-button {
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.32),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.28);
  border-radius: 8px;
  height: 3rem;
  padding-left: 20px;
  margin-bottom: 12px;
}
.SelectDB .btn {
  width: 170px !important;
  margin-bottom: 12px;
  margin-inline: 6px;
}

.SelectDB .ui-button__content {
  font-size: 1rem;
  padding-left: 8px;
}
.SelectDB .btn .ui-button__content {
  font-size: 1rem;
  padding-left: 0px;
}

@media (max-width: 782px) {
  .image-logo {
    max-width: 120px !important;
    width: 100%;
  }
}
