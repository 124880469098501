.tutors-top-banner {
  height: 168px;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 20px;
  .tutors-top-banner-text {
    @media screen and (max-width: 568px) {
      font-size: 1.2rem;
    }
  }
}
