.student-assignment {
  @media only screen and (max-width: 505px) {
    .Attendance.h-ctr .table-wrapper {
      height: calc(100vh - 61px);
    }

    .Attendance.h-ctr .class-wrapper {
      height: calc(100vh - 209px);
    }

    .Attendance .d-sm-center {
      justify-content: unset;
    }

    .Attendance.h-cls .d-sm-center {
      justify-content: unset;
    }
  }
}
