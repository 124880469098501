.profile_logo_div {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.profile_logo_div {
  /* width: 50px;
  height: 50px; */
  flex-shrink: 0;
  margin-right: 5px;
}

.role-login-card-container {
  /* padding: 250px 0; */
  width: 100%;
}

.main_div {
  /* overflow: hidden; */
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.card_div {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.profile_logo {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
}

.role-login-card {
  cursor: pointer;
  min-height: 300px;
}

.role-login-card:hover {
  background-color: rgb(240, 241, 248) !important;
}

.profile_info .profile_info_role {
  font-size: 14px;
  margin-right: 5px;
}

.role-login-card .role_info,
.role-login-card .role_blue,
.role-login-card .role_warm,
.role-login-card .role_red {
  position: relative;
}

.role-login-card .school_logo_div {
  width: 150px;
  min-height: 150px;
}

.role-login-card .dot {
  /* background-color: red; */
}

.role-login-card .dot::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  left: 5px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.role-login-card .role_red::before {
  background-color: #f25767;
}

.role-login-card .role_warm::before {
  background-color: #ffaa44;

  transform: translate(-50%, -50%);
}

.role-login-card .role_blue::before {
  background-color: #6264a7;
}

.role-login-card .role_info::before {
  background-color: #096dd9;
}

.fst-italic {
  font-style: italic;
}

@media only screen and (max-width: 1199px) {
  .role_login h6 {
    font-size: 12px !important;
  }

  .role_login .role_info {
    font-size: 10px !important;
  }

  .role-login-card .role_info::before {
    height: 5px;
    width: 5px;
  }
}

@media only screen and (max-width: 992px) {
  .role_login h6 {
    font-size: 1rem !important;
  }

  .role_login .role_info {
    font-size: 0.8rem !important;
  }

  .role-login-card .role_info::before {
    height: 10px;
    width: 10px;
  }

  .role-login-card-container {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 575px) {
  .card.role-login-card {
    width: 50%;
    margin: 10px auto;
  }
}

@media only screen and (max-width: 460px) {
  .card.role-login-card {
    width: 100%;
    margin: auto;
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  height: 20px;
  width: 200px;
  border-radius: 5px;
}

.skeleton_img {
  width: 150px;
  min-height: 150px;
}

.skeleton-card {
  min-height: 300px;
  margin: 10px;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}