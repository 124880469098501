.tutor-card {
  width: 100%;
  height: 344px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    .overlay {
      height: fit-content;
      opacity: 1;
    }
    .tutor-speciality {
      margin-bottom: 0px !important;
    }
  }
  .recommended-label {
    background-color: #c4314b;
    z-index: 1;
    width: fit-content;
    padding: 12px 10px;
    position: absolute;
    left: 15px;
    top: 15px;
    div {
      color: #fff;
      font-weight: 500;
    }
  }
  .star-wrapper {
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 15px;
    width: 33px;
    height: 33px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 3.2px 7.2px 0px #00000021;
  }
  .tutor-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .tutor-info {
    width: 100%;
    padding: 20px;
    .tutor-name {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      color: #fff;
      text-transform: capitalize;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tutor-description {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      color: #fff;
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    .slots-status {
      background-color: #00c064;
      width: fit-content;
      padding: 12px 17px;
      div {
        color: #fff;
        font-weight: 500;
      }
    }
    .no-slots-status {
      background-color: #ffb800;
      width: fit-content;
      padding: 12px 17px;
      div {
        color: #fff;
        font-weight: 500;
      }
    }
    .tutor-speciality {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
    }
    .view-profile-button {
      border-radius: 10px;
      width: 100%;
      max-width: 100% !important;
    }
  }
  .overlay {
    position: absolute;
    border-radius: 15px;
    bottom: 0;
    width: 100%;
    height: 30%;
    max-height: 60%;
    background-color: #5b5a5fa8;
    backdrop-filter: blur(18px); /* Apply blur effect */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: height 0.7s ease, opacity 0.7s ease;
    height: 30%;
    opacity: 1;
  }
}
