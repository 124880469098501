.collection-main-credits {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 17px;
  row-gap: 15px;
  width: 100%;
  overflow: auto;
  position: relative;
  padding: 17px;
  display: block;
}

.collection-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.collection-card .like-font {
  color: #dc3545;
}

.collection-card .like-icon {
  width: 14px;
  height: 14px;
}

.collection-card .user-image {
  width: min(100%, 24px);
  height: min(100%, 24px);
}

.collection-card .username {
  color: #1890ff;
  font-weight: 500;
}

.collection-card .moreInfoBtn {
  background-color: #585a96;
}

.collection-card .addToCollection {
  border: 1px solid #585a96;
  color: #585a96;
}
.img-div {
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.img-div svg {
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.collection-card .collection-img {
  width: 100%;
  height: 100%;
}
.footer {
  margin-top: auto;
  padding-top: 2rem;
}

.preview-dialog {
  text-align: center;
  width: fit-content !important;
}

.text-field-credits > div {
  background-color: white !important;
}

.select-credits > div {
  font-family: "Karla", sans-serif;
}

#combo-box-demo-label {
  font-family: "Karla", sans-serif;
  font-size: 14px;
  z-index: 0;
}

.combo-box-demo-mobile-label {
  font-family: "Karla", sans-serif;
  font-size: 14px;
  z-index: 0;
}

#combo-box-demo {
  font-family: "Karla", sans-serif;
  font-size: 14px;
}

.combo-box-demo-mobile {
  font-family: "Karla", sans-serif;
  font-size: 14px;
}

.css-1rndd9j {
  font-family: "Karla", sans-serif;
  font-size: 14px;
}

.iconButton:disabled,
.iconButton[disabled] {
  color: green !important;
}

.rejectIcon:disabled,
.rejectIcon[disabled] {
  color: red !important;
}

.assigned:disabled,
.assigned[disabled] {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

#combo-box-demo-helper-text {
  display: contents;
  font-size: 10px;
}

.combo-box-demo-mobile-helper-text {
  display: contents;
  font-size: 10px;
}

.combo-box-demo-mobile > div {
  height: 30px !important;
}

.combo-box-demo-mobile > div > label {
  font-size: 13px !important;
}

.combo-box-demo-mobile > div > :nth-child(2) {
  height: 30px !important;
  padding: 0px !important;
  justify-content: center;
}

.css-1og5tzn-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-endAdornment {
  right: 0px !important;
}

.combo-box-demo-mobile > div > :nth-child(2) > input {
  font-size: 13px !important;
  font-size: 13px !important;
  padding: 0px !important;
  min-width: 12px !important;
  position: absolute;
}

.css-gs0km7-MuiAutocomplete-root {
  margin: 8px;
  min-width: 93px !important;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 70% !important;
}
.css-gs0km7-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  min-width: 50px !important;
}

body {
  font-family: "lato", sans-serif;
}
.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.mobile-container {
  .container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

h2 {
  font-size: 26px;
  margin: 20px 0;
  text-align: center;
  small {
    font-size: 0.5em;
  }
}

.mobile-table {
  padding-left: 0px;
  /* padding-top: 15px; */
}

.responsive-table {
  padding-left: 0px;
  padding-top: 15px;

  li {
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .table-header {
    background-color: #6264a7;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    padding: 15px 17px;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  }

  .col-1 {
    width: 9%;
  }
  .col-2 {
    width: 8%;
  }
  .col-3 {
    width: 11%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-4 {
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-5 {
    width: 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-6 {
    width: 14%;
  }
  .col-7 {
    width: 14%;
  }

  .col-8 {
    width: 14%;
    display: flex;
  }

  .col-9 {
    width: 23%;
    display: flex;
  }
}

.data-teacher {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  display: flex;
  align-items: center;
}

.email-wrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
}

.outlined-basic > :nth-child(1) {
  font-family: Karla, sans-serif;
}

.outlined-basic-mobile > :nth-child(1) {
  font-family: Karla, sans-serif;
  font-size: 13px;
  margin-top: -3px;
}

.outlined-basic-mobile > :nth-child(2) {
  height: 30px;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: black !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #6c6c6c !important;
}

.text > div {
  font-size: 13px;
  font-family: Karla, sans-serif;
  height: 30px;
}

.search-bar > div {
  font-family: Karla, sans-serif;
}

.assigned > span {
  left: 24px;
  top: 9px;
}

.switch-label > :nth-child(2) {
  font-family: Karla, sans-serif;
  font-size: 14px;
}
