.classrooms-container {
  .SmallSelector .ui-dropdown__container {
    width: 11rem !important;
  }
  .SmallSelector .ui-list {
    width: 11rem !important;
  }
  .SmallSelector .ui-dropdown__selecteditem__header {
    max-width: 40px;
  }
  .SmallSelector .ui-dropdown__selected-items {
    max-height: 4.5rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .classrooms {
    padding: 20px 20px 0;
    .all-classrooms {
      .classrooms-cards-wrapper {
        max-height: calc(100vh - 270px);
        overflow-y: auto;
        @media screen and (max-width: 847px) {
          max-height: calc(100vh - 300px);
        }
        @media screen and (max-width: 647px) {
          max-height: calc(100vh - 337px);
        }
      }
    }
  }
  .classroom-students-cards-wrapper {
    padding: 10px 0 10px 10px;
    max-height: calc(100vh - 320px);
    overflow: auto;
    @media screen and (max-width: 764px) {
      max-height: calc(100vh - 350px);
    }
  }

  .m1 {
    margin: 10px 10px 0;
  }
  .img {
    border-radius: 6px;
  }
  .seg {
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
      0 1px 3px 1px rgb(60 64 67 / 15%);
    transition: #fff 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 8px;
    // margin: 8px !important;
  }
  // .search-input {
  //   width: 11rem !important;
  // }
  .pagination {
    .active {
      border: 0 !important;
    }
  }
  .pagination-dropdown .ui-dropdown__container {
    width: 11rem !important;
  }
  .pagination-dropdown .ui-list {
    width: 11rem !important;
  }
  .pagination-dropdown .ui-dropdown__selecteditem__header {
    max-width: 40px;
  }
  .pagination-dropdown .ui-dropdown__selected-items {
    max-height: 4.5rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media screen and (max-width: 425px) {
    .classrooms {
      padding: 20px 5px;
    }
  }
}
