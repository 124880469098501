.mic-bg-color{
    background: #5B5FC7;
    color: white;
}

.pause-bg-color{
    background: #f34f47;
    color: white;
}

.play-bg-color{
    background: #ecb403;
    color: white;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.icon-padding{
    padding: 9px !important;
}