.scheduleTable {
  grid-column: span 12;
  margin-left: 50px;
  margin-right: 50px;
}

.scheduleTable .ui-table__header .ui-table__cell:first-child {
  /* width: 30vw; */
  min-width: 21vw;
  /* max-width: 30vw; */
  /* word-break: break-all; */
}

.scheduleTable .ui-table__cell:first-child {
  /* width: 32vw; */
  min-width: 22vw;
  /* max-width: 32vw; */
  /* word-break: break-all; */
}

.Schedule .ui-segment {
  box-shadow: none;
}

.Schedule .ui-segment.scheduleTable {
  background: #ffffff1a;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  border-radius: 4px;
}

.Schedule .BoxChart {
  width: auto;
  height: auto;
  overflow: visible;
}

.Schedule .Analytics .BoxChart {
  max-width: 100% !important;
}

/* .scheduleTable .table-wrapper .ui-table__row{
    display: block !important;
    height: auto !important;
}
.Attendance .ui-table {
    min-width: min-content;
    width: fit-content;
} */

.Schedule .css-48ayfv,
.Schedule .css-1r4vtzz {
  box-shadow: none;
  background-color: #f3f2f1;
  /* margin-top: 16px; */
  padding-top: 7px;
  padding-bottom: 7px;
  width: 256px;
  font-size: 15px;
}

.Schedule .css-1qprcsu-option,
.Schedule .css-1vr111p-option {
  padding-right: 25px;
}

.Schedule .css-1qprcsu-option {
  background-color: transparent !important;
  font-weight: normal !important;
}

.Schedule .css-1vr111p-option:hover {
  background-color: #f3f2f1;
}

.Schedule .css-1pcexqc-container {
  box-shadow: rgb(0 0 0 / 10%) 0px 0.8rem 1rem -0.2rem;
  width: 19rem !important;
}

.Schedule .gridRows {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}

.Schedule .gridHeader {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
}

.text-white {
  color: #fff !important;
}

.Schedule .ui-box {
  /* text-align: center; */
}

/* @media (min-width:746px) and (max-width:843px) { */
@media only screen and (max-width: 1082px) {

  /* .Schedule .ui-table__row{
        display: block !important;
        height: auto !important;
    } */
  /* .Schedule .gridHeader{
        display: none;
    } */
  .Schedule .ui-table__row {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 712px) {
  .Schedule.h-ctr .Analytics {
    height: calc(100vh - 61px);
  }

  .Schedule.s-ctr .Analytics {
    height: calc(100vh - 230px);
  }
}

@media only screen and (max-width: 843px) {
  .Schedule .BoxChart {
    margin: 0 10px;
  }

  .scheduleTable {
    margin: 0 10px;
  }

  .scheduleTable * {
    font-size: 11px;
  }

  .scheduleTable {
    padding: 0;
    margin: 0;
  }

  .Schedule .ui-segment.scheduleTable {
    background: white;
    padding: 3px;
  }

  .scheduleTable .gridRows .ui-table__row .ui-layout__main {
    display: none;
  }

  .scheduleTable .ui-table__cell:first-child {
    min-width: 18vw;
  }

  .scheduleTable .ui-table__header .ui-table__cell:first-child {
    min-width: 18vw;
  }

  .gridHeader-small {
    display: flex !important;
  }

  .gridHeader.sm-hide {
    display: none !important;
  }

}

@media only screen and (min-width: 843px) {
  .gridHeader-small {
    display: none !important;
  }
}