.meeting-duration-wrapper {
  padding: 30px;
  // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  //   rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  height: fit-content;
  width: 500px;
  margin: 0 auto;
  background-color: #fff;
  @media screen and (max-width: 1500px) {
    width: 400px;
  }
  @media screen and (max-width: 1200px) {
    margin: 0;
    margin-top: 30px;
    width: 500px;
  }
  @media screen and (max-width: 530px) {
    width: 100%;
  }
  .meeting-duration-input {
    input {
      background-color: #d6d4d475;
      width: 100%;
    }
  }
  .update-duration-button {
    margin-top: 15px;
  }
}
