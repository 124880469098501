.slots-wrapper {
  // justify-content: space-between;
  .slot-shift {
    .slot-shift-header {
      padding: 10px 30px;
      background-color: #6264a7;
      border-radius: 5px;
      color: #fff;
      text-align: center;
      text-transform: capitalize;
      min-width: 100px;
      @media screen and (max-width: 767px) {
        width: 100px !important;
        padding: 10px 15px !important;
      }
    }
    .slot-shift-times-wrapper {
      margin-top: 20px;
      .slot-shift-time {
        padding: 10px 25px;
        background-color: #e1e1df;
        border-radius: 25px;
        border: 0;
        cursor: pointer;
        text-align: center;
        &:disabled {
          background-color: #e7e7e4;
          color: #c9c9c7;
          cursor: not-allowed;
        }
      }
      @media screen and (max-width: 767px) {
        .slot-shift-time {
          padding: 13px 15px !important;
        }
      }
      .selected {
        background-color: #6264a7 !important;
        color: #fff !important;
      }
      .notScheduled {
        background-color: #e7e7e4;
        color: #c9c9c7;
        cursor: not-allowed;
      }
    }
  }
}
