.Assignment {
  height: calc(100vh - 145px);
  background-color: white;
}
input::placeholder {
  color: white;
}
.student-assignments-table {
  padding-top: 10px;
  max-height: calc(100vh - 240px);
  overflow: auto;
}

@media only screen and (max-width: 1026px) {
  .student-assignments-table {
    max-height: calc(100vh - 300px);
  }
}
@media only screen and (max-width: 992px) {
  .student-assignments-table {
    max-height: calc(100vh - 263px);
  }
}
@media only screen and (max-width: 505px) {
  .student-assignments-table {
    max-height: calc(100vh - 265px);
  }
}

#datePicker-1 .ui-input__input {
  width: 140px;
}

#datePicker-1 {
  display: flex;
}

.Assignment .ui-button {
  border-radius: 4px;
}

.Assignment .table-wrapper.w-100 {
  width: 100vw;
  text-align: center;
}

.Assignment .assignment-table-wrapper-w-100 {
  text-align: unset !important;
}

.Assignment .ui-table {
  min-width: 963px;
}

.ui-table__cell .ui-button {
  border-radius: 16px;
}

.Assignment input {
  font-family: inherit;
  font-size: 14px !important;
  font-weight: 600;
}

.Assignment .ui-table {
  background-color: transparent;
}

.ui-table__row.ui-table__header,
.ui-table__row.ui-table__header .ui-label {
  background-color: transparent;
  height: 50px;
}

.Assignment .class-wrapper {
  /* width: 340px; */
  width: 100%;
  margin-right: 3px;
  overflow-y: auto !important;
  height: calc(100vh - 199px);
}

.Whiteboard.h-cls.s-ctr > :nth-child(2) > div > div {
  max-height: calc(100vh - 151px);
}

.Whiteboard.h-cls.s-ctr.fullscreen > :nth-child(2) > div > div {
  height: 100%;
}

.Assignment .table-wrapper {
  position: absolute;
  width: calc(100vw - 378px);
  overflow: auto;
  /* height: calc(100vh - 157px); */
}

.ui-table__row.ui-table__header .ui-checkbox .ui-checkbox__indicator {
  align-self: center;
}

.note-input input {
  width: 300px;
}

.multi-slc .ui-text {
  display: inline-block;
}

.Assignment .card {
  margin-bottom: 10px !important;
  border-radius: 7px !important;
  cursor: pointer;
  border: 2px solid #d3d3d314 !important;
  width: auto;
}

.selectedCard {
  width: auto !important;
  margin-bottom: 10px !important;
  border-radius: 7px !important;
  cursor: pointer;
  background-color: #edebe9 !important;
  border: 2px solid gainsboro !important;
}

.refreshMsg {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.33333;
}

.Assignment .mode-control .ui-menubutton__menu {
  width: 100% !important;
}

.Assignment .mode-control .ui-popup__content__content {
  display: flex;
  width: 52% !important;
}

.Assignment .assignment-availableClass {
  flex-direction: column;
}

.Whiteboard .class-wrapper {
  height: calc(100vh - 199px) !important;
}

@media (min-width: 1060px) and (max-width: 1380px) {
  .Assignment .table-wrapper {
    width: calc(100vw - 273px);
    /* height: calc(100vh - 218px); */
  }

  .Assignment .class-wrapper {
    height: calc(100vh - 273px);
  }
}

@media (max-width: 1280px) {
  .Assignment .table-wrapper {
    /* height: calc(100vh - 208px); */
  }

  .Assignment .class-wrapper {
    height: calc(100vh - 203px);
  }
}

@media only screen and (max-width: 1060px) {
  .table-wrapper .ui-table__row {
    display: block !important;
    height: auto !important;
  }

  .Assignment .ui-table {
    min-width: min-content;
  }

  .Assignment
    .ui-table__row:first-child
    .ui-table
    .ui-box.ui-table__cell__content {
    width: auto;
  }

  .Assignment .ui-table .ui-box.ui-table__cell__content {
    width: -webkit-fill-available;
  }

  .note-input input {
    width: -webkit-fill-available;
  }

  .stud-name span {
    font-weight: 600;
  }

  .multi-slc {
    position: fixed;
    background: white;
    z-index: 1;
    width: calc(100% - 386px);
    margin-top: -2px;
    align-items: center;
    padding-left: 15px;
    margin-left: -15px;
    box-shadow: 0 15px 18px 0px rgb(200 200 200 / 30%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .table-wrapper.table-wrapper-w-100 .multi-slc {
    width: calc(100% - 150px);
  }

  .table-wrapper .action-notes {
    display: none;
  }

  .Assignment.s-ctr .table-wrapper {
    /* height: calc(100vh - 217px); */
    /* height: calc(100vh - 227px); */
  }

  .Assignment.h-ctr .table-wrapper {
    /* height: calc(100vh - 235px); */
  }

  .Assignment.s-ctr .class-wrapper {
    height: calc(100vh - 259px);
  }

  .Assignment.h-ctr .class-wrapper {
    height: calc(100vh - 275px);
  }

  .d-none {
    display: none !important;
  }

  .Assignment .table-wrapper .ui-avatar {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}

@media (min-width: 810px) and (max-width: 843px) {
  .Assignment.s-ctr .table-wrapper {
    height: calc(100vh - 157px);
  }

  .Assignment.s-ctr .class-wrapper {
    height: calc(100vh - 259px);
  }
}

@media (min-width: 746px) and (max-width: 809.99px) {
  .Assignment.s-ctr .table-wrapper {
    /* height: calc(100vh - 157px); */
  }

  .Assignment.s-ctr .class-wrapper {
    height: calc(100vh - 319px);
  }
}

@media only screen and (max-width: 745.99px) {
  .Assignment.s-ctr .table-wrapper {
    /* height: calc(100vh - 157px); */
  }

  .Assignment.s-ctr .class-wrapper {
    height: calc(100vh - 329px);
  }
}

@media only screen and (max-width: 1060px) {
  .ui-dialog {
    box-sizing: unset !important;
    /* width: 70% !important; */
  }
}

@media only screen and (max-width: 689px) {
  .Assignment.h-cls .assignment-class-selected {
    display: none !important;
  }

  .Assignment.h-cls .table-wrapper {
    width: 100%;
    padding-inline: 20px;
  }

  .Assignment.h-cls .multi-slc {
    width: calc(100% - 30px);
  }
}

.Assignment.s-ctr .class-wrapper {
  /* height: 100% !important; */
  /* width: fit-content; */
  width: 100%;
}

@media only screen and (max-width: 611px) {
  .Assignment.s-ctr .table-wrapper {
    /* height: calc(100vh - 157px); */
  }

  .Assignment.h-ctr .table-wrapper {
    /* height: calc(100vh - 215px); */
  }

  .Assignment.s-ctr .class-wrapper {
    height: calc(100vh - 330px);
  }

  .Assignment.h-ctr .class-wrapper {
    height: calc(100vh - 232px);
  }
}

@media only screen and (max-width: 505px) {
  .Assignment.h-ctr .table-wrapper {
    /* height: calc(100vh - 190px); */
    /* height: calc(100vh - 150px); */
  }

  .Assignment.h-ctr .class-wrapper {
    height: calc(100vh - 209px);
  }

  /* .Assignment .d-sm-center {
    justify-content: center;
  }

  .Assignment.h-cls .d-sm-center {
    justify-content: start;
  } */
}

@media only screen and (max-width: 358px) {
  .Assignment.h-ctr .table-wrapper {
    /* height: calc(100vh - 60px); */
  }

  .Assignment.h-ctr .class-wrapper {
    height: calc(100vh - 102px);
  }
}

@media only screen and (max-width: 328px) {
  .Assignment.h-ctr .table-wrapper {
    /* height: calc(100vh - 125px); */
  }

  .Assignment.h-ctr .class-wrapper {
    height: calc(100vh - 272px);
  }
}

.checkBoxImage.checked .ui-checkbox__indicator {
  filter: invert(81%) sepia(153%) saturate(426%) hue-rotate(80deg)
    brightness(74%) contrast(116%);
}

.checkBoxImage {
  width: 2rem;
  margin-right: 0.6rem;
}

.checkBoxImage .ui-checkbox__indicator,
.checkBoxImage:hover .ui-checkbox__indicator {
  background-image: url("/src/Assets/images/svg/imageCheck.svg") !important;
  width: 20px;
  height: 24px;
  border: none;
}

.ui-table__row.bgVirtual {
  background-color: #04c68a17;
}

.bgVirtual .ui-input__input {
  background-color: rgba(255, 255, 255, 0.685);
}

.d-none {
  display: none;
}

.w-125 {
  width: 125px;
}

.pl-10 {
  padding-left: 10px;
}

.w-125 .ui-card.selectedCard,
.w-125 .ui-card.card {
  width: 100%;
  /* padding-bottom: 7px; */
}

.class-wrapper.w-125 {
  width: auto;
  padding-right: 6px;
}

.compact-mode-icon {
  width: 118px;
  text-align: center;
  padding-top: 12px;
  cursor: pointer;
}

.compact-mode-icon img {
  width: 24px;
}

.Assignment .table-wrapper-compactMode {
  width: calc(100vw - 133px);
}

.table-wrapper.table-wrapper-w-100 {
  width: calc(100vw - 140px);
}

.flex-grow {
  flex-grow: 1;
}

.frameTag {
  width: 100%;
  border: none;
}

.frameTagimg {
  max-width: 640px;
  width: fit-content;
  width: inherit;
}

@media only screen and (max-width: 689px) {
  .frameTag {
    height: calc(100vh - 61px) !important;
  }
}

@media (max-width: 620px) {
  .Whiteboard .heroframe {
    display: none !important;
  }
}

.Whiteboard .heroframe {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 576px) {
  .Assignment .assignment-availableClass {
    margin-right: 0;
  }

  .Whiteboard .pl-sm-1 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .Assignment .pl-sm-1 {
    padding-left: 1rem !important;
  }

  .Assignment .table-wrapper {
    padding-inline: 0 !important;
  }

  .Assignment .stud-name-lable,
  .Whiteboard .stud-name-lable {
    font-size: smaller;
  }

  .Assignment.h-cls .multi-slc {
    /* width: 100%; */
    width: 97%;
  }

  .Assignment .class-wrapper {
    width: 280px;
  }

  .Assignment .class-wrapper.w-125 {
    width: auto;
  }
}

.generate-avatar-dialog.ui-dialog {
  /* width: 95% !important; */
  width: 95vw !important;
}
