.information-container{
    .startup-info-table{
        .ui-table__row{
            height: fit-content;
        }
    }
    .hidden-element{
        display: none;
    }
    .text-center{
        text-align: center;
    }
    .w-fit-content{
        width: fit-content;
    }
    .fw-600{
        font-weight: 600;
    }
}