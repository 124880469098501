.student-assignment-list {
  width: 100vw;
}
.student-assignment-list .assignment-list {
  padding: 25px;
}
.assignment-status-filter .filter-item {
  margin: 10px;
}
@media only screen and (max-width: 350px) {
  .student-assignment-list .assignment-list {
    padding: 0;
  }
  .assignment-status-filter {
    flex-direction: column;
    margin-top: 10px;
  }
}
