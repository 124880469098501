.setting-event-tag-container {
  width: 100%;
  padding-top: 20px;
  .event-tags-wrapper {
    max-height: calc(100vh - 330px);
    overflow-y: auto;
    margin: 10px 0;
    padding: 0 20px;
    @media screen and (max-width: 1596px) {
      max-height: calc(100vh - 373px);
    }
    @media screen and (max-width: 1447px) {
      max-height: calc(100vh - 415px);
    }
    @media screen and (max-width: 991px) {
      max-height: calc(100vh - 325px);
    }
  }
}
