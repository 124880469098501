.text-feedback-container {
  padding-bottom: 10px;
  .text-feedback {
    // margin-bottom: 50px;
    padding: 25px;

    .chatgpt-icon-button {
      min-width: unset;
      .chatgpt-icon {
        width: 25px;
        height: 25px;
      }
    }

    .feedback-questions {
      margin: 10px 0;

      .question-wrapper {
        margin: 10px 0;
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #dcd7d7;
        box-shadow: rgba(0, 0, 0, 0.1) 0.1rem 0.1rem 0.5rem -0.075rem;

        .question-action-wrapper {
          margin-top: 25px;
        }

        .delete-option-btn {
          min-width: auto;
          margin: 0 15px 0 25px;
        }
      }
    }

    .file-browse-section-card {
      .file-browse-section-outer {
        margin: 5px;
        height: 100%;
        border-radius: 4px;
      }
    }
    .videonote-box {
      margin: 10px;
      border: 1px solid black;
      background: #333;
      height: 170px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .add-new-question-btn {
      // margin-top: 10px;
      .ui-button {
        border-radius: 4px 0 0 4px;
      }
      .ui-splitbutton__toggle {
        border-radius: 0 4px 4px 0;
      }
    }
    .feedback-form-footer-wrapper {
      margin-top: 15px;
      row-gap: 10px;
      .action-btn-wrapper {
        margin-left: auto;
      }
    }

    .share-with-option {
      min-width: fit-content;
      padding: 0;
      &.selected-share-with-option {
        font-weight: 600;
        color: #6264a7;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .text-feedback {
      // padding: 5px;
    }
  }
  @media only screen and (max-width: 576px) {
    .text-feedback {
      padding-bottom: 25px;
    }
  }
}

.video-question-wrapper {
  video {
    height: 100%;
    width: 100%;
    max-height: 350px;
    max-width: 467px;
    border-radius: 10px;
  }
  .vider-recorder-wrapper {
    max-height: 350px;
    max-width: 467px;
    margin: auto;
  }
}
