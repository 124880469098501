.setting-profile-container {
  overflow-y: autp;
  position: relative;
  width: 100%;
  .setting-profile-wrapper {
    width: 60%;
    margin: 40px auto;
  }

  .MuiButtonBase-root > .css-1d6wzja-MuiButton-startIcon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &.tabs {
    margin: 0;
  }

  .tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
  }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    color: #4193ff;
  }

  .tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    color: #000000;
  }

  .skill-container,
  .interest-container {
    padding: 0 20px;
    height: calc(100vh - 425px);
    overflow-y: auto;
    width: 100%;
    margin: 20px auto;
  }

  @media screen and (max-width: 991px) {
    .setting-profile-wrapper {
      width: calc(100% - 20px);
      margin: 10px;

      .skill-container,
      .interest-container {
        height: calc(100vh - 300px);
      }
    }
  }
  @media screen and (max-width: 556px) {
    .wrapper {
      margin: 40px 10px;
      padding-bottom: 40px;
    }
  }
}
