.event-form.google-login-btn {
  display: none !important;
}
.meeting-details-form-wrapper {
  min-width: 30vw;
  max-width: 90vw;
  @media screen and (max-width: 767px) {
    min-width: 90vw !important;
  }
  .header {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
  .sub-header {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0 !important;
    padding-bottom: 5px;
  }
  .meeting-users-selector {
    .ui-list {
      display: none;
    }
  }
}
