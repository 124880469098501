.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 50px;
}

.hover-card {
  cursor: pointer;
}

.icon-attendance {
  background: #6264a7;
  box-shadow: 0 0.5rem 1rem rgb(98 100 167 / 53%);
}

.active {
  border: 2px solid #245c23 !important;
}

.active-round {
  background: #245c23;
}

.extension-button {
  /* margin-left: 0.5rem !important; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
}

#extension-dialog {
  width: 30% !important;
}

.captcha-content {
  display: grid;
  gap: 20px;
}

.progressbar-loader {
  height: 18px !important;
  width: 18px !important;
  color: white !important;
}

.button-confirm {
  display: flex;
  align-items: center;
}

.extention_name {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
